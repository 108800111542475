import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import React, {
    // Fragment
} from 'react'
import 'react-modern-drawer/dist/index.css'
import {
    //   Divider,
    Form,
    //   Grid,
    Header,
    Icon,
    Popup,
    //   Segment,
    //   Accordion,
    Dropdown,
    //   Button
} from 'semantic-ui-react'
// import { CopyToClipboard } from 'react-copy-to-clipboard'
import {
    benchmarkType,
    // profile_settings, 
    settings_benchmark,
    //  settings_general 
} from './settings'
// import {
//   updateSettings,
//   doShowSettings,
//   filterProfileSettings,
//   resetSettings,
// } from 'actions/commonActions'

// import CustomSlider from 'components/CustomSlider'
// import { makeRequest } from 'actions/directionsActions'
// import { makeIsochronesRequest } from 'actions/isochronesActions'
// import Benchmark from './Benchmark'
import Checkbox from 'components/Checkbox'
import { updateSettings } from 'actions/commonActions'
import CustomSlider from './slider'


class BenchMarkControl extends React.Component {
    static propTypes = {
        // profile: PropTypes.string.isRequired,
        // loading: PropTypes.bool,
        settings: PropTypes.object,
        dispatch: PropTypes.func.isRequired,

    }

    handleBenchMarkValue = (e, data) => {
        const { value, name } = data
        const { dispatch } = this.props
        dispatch(
            updateSettings({
                name,
                value,
            })
        )
    }

    handleUpdateSettings = ({ name, value }) => {
        const { dispatch } = this.props
        dispatch(
          updateSettings({
            name,
            value,
          })
        )
        // dispatch(updateCost())
      }

    handleBenchMarkTypeChange = (e, data) => {
        const { value, name } = data
        const { dispatch } = this.props
        dispatch(
            updateSettings({
                name,
                value,
            })
        )
    }
    constructor(props) {
        super(props)
        this.state = {
            extraSettings: {},
        }
    }

    render() {
        const { dispatch } = this.props
        const { settings } = this.props

        return (
            <Form size={'small'} >
                <Header as="h4">Benchmark</Header>
                <div className={'flex flex-row justify-between'}>
                    {settings_benchmark.all.boolean.map((option, key) => {
                        return (
                            <div key={key} className="flex">
                                <Checkbox
                                    key={key}
                                    option={option}
                                    dispatch={dispatch}
                                    settings={settings}
                                    handleUpdateSettings={this.handleUpdateSettings}
                                />
                                <div
                                    style={{
                                        marginLeft: 'auto',
                                        marginRight: '0px'
                                    }}
                                >
                                    <Popup
                                        content={option.description}
                                        size={'tiny'}
                                        trigger={<Icon color="grey" name="help circle" />}
                                    />
                                </div>
                            </div>
                        )
                    })}
                </div>
                {settings.use_benchmark &&
                <>
                    <div className="flex">
                        <Dropdown
                            placeholder="Select Benchmark Type"
                            fluid
                            onChange={this.handleBenchMarkTypeChange}
                            value={settings.benchmark_type}
                            selection
                            name={'benchmark_type'}
                            options={benchmarkType.enums}
                        />
                        <div
                            style={{
                                marginLeft: 'auto',
                            }}
                        >
                            <Popup
                                content={benchmarkType.description}
                                size={'tiny'}
                                trigger={
                                    <Icon color="grey" name="help circle" />
                                }
                            />
                        </div>
                    </div>


                <CustomSlider
                    option={benchmarkType.sliderSettings[settings.benchmark_type]}
                    dispatch={dispatch}
                    settings={settings}
                    handleUpdateSettings={this.handleUpdateSettings}
                /></>
                            }
            </Form>

        )

    }
}

const mapStateToProps = (state) => {
    const {
        production,
        settings} = state.common
    return {
        production,
        settings
    }
}

export default connect(mapStateToProps)(BenchMarkControl)
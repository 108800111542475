import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Divider,
  Dropdown,
  // Popup,
  // Icon
} from 'semantic-ui-react'

import Waypoints from './Waypoints'

// import { ProfilePicker } from '../../components/profile-picker'
import { SettingsButton } from '../../components/SettingsButton'
import { SettingsFooter } from 'components/SettingsFooter'

import { productionType } from './production-options'
import {
  // updateSettings,
  // updateProfile,
  doShowSettings,
  // updatePermalink,
} from 'actions/commonActions'
import { updateCost, updateProductionSettings } from 'actions/productionActions'
// import { production } from 'reducers/production'
// import {settings} from 'reducers/common'
// import { clearIsos, makeIsochronesRequest } from 'actions/isochronesActions'

class ProductionControl extends React.Component {
  static propTypes = {
    // profile: PropTypes.string.isRequired,
    // loading: PropTypes.bool,
    settings: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
  }

  // handleUpdateProfile = (event, data) => {
  //   const { dispatch } = this.props
  //   dispatch(updateProfile({ profile: data.valhalla_profile }))
  //   dispatch(updatePermalink())
  // }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //   const { dispatch } = this.props
  //   if (this.props.profile !== nextProps.profile) {
  //     dispatch(makeIsochronesRequest())
  //   }
  // }

  // handleRemoveIsos = () => {
  //   const { dispatch } = this.props
  //   dispatch(clearIsos())
  // }

  handleSettings = () => {
    const { dispatch } = this.props
    dispatch(doShowSettings())
  }

  handleProductionTypeChange = (e, data) => {
    const { value, name } = data
    const { dispatch } = this.props
    dispatch(
      updateProductionSettings({
        name,
        value,
      })
      
    )
    dispatch(updateCost())
  }

  render() {
    const {
    //   //  profile, 
    //   //  loading, 
       settings 
      } = this.props
    return (
      <React.Fragment>
        <div className="flex flex-column content-between">
          <div className="pa2 flex flex-row justify-between">
          <Dropdown
                                placeholder="Select H2 production method"
                                fluid
                                onChange={this.handleProductionTypeChange}
                                value={settings.production_type}
                                selection
                                name={'production_type'}
                                options={productionType.enums}
                              />
                       {/* <Popup 
                              content={productionType.description}
                              size={'tiny'}
                              trigger={<Icon color="grey" name="help circle" />}
                            /> */}
            <SettingsButton handleSettings={this.handleSettings} />
          </div>
          <Waypoints />
          <Divider fitted />
          <SettingsFooter />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  // const {
    // profile,
    // loading,
    // settings
  //  } = state.common
  const { settings } = state.production
  return {
    // profile,
    // loading,
    // production,
    settings
  }
}

export default connect(mapStateToProps)(ProductionControl)

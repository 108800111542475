import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import {
  Segment,
  Popup,
  Label,
  Divider,
  Icon,
} from 'semantic-ui-react'
import L from 'leaflet'
import * as R from 'ramda'
import {
  makeCostRequest,
  makeRequest
} from 'actions/transportActions'
import { downloadFile } from 'actions/commonActions'
// import Summary from './Summary'
// import Maneuvers from './Maneuvers'
import { ROUTING_URL } from 'utils/transport'
class OutputControl extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    profile: PropTypes.string,
    activeTab: PropTypes.number,
    successful: PropTypes.bool,
    results: PropTypes.object,
    cost: PropTypes.numeric,
    cost_details: PropTypes.object,
  }
 
  constructor(props) {
    super(props)

    this.state = {
      showResults: false,
    }
    this.showManeuvers = this.showManeuvers.bind(this)
  }

  // necessary to calculate new routes the tab was changed from isochrone tab
  // need to do this every time, because "profile" is global (otherwise we would
  // calculate new when the profile was changed while being on the iso tab)
  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (nextProps.activeTab === 0 && this.props.activeTab === 1) {
      nextProps.dispatch(makeRequest())
      // nextProps.dispatch(makeCostRequest())
    }
    if (!R.equals(this.props.results[ROUTING_URL].data.distance, nextProps.results[ROUTING_URL].data.distance)) {
      nextProps.dispatch(makeCostRequest())
    }
    if (nextProps.activeTab === 0) {
      return false
    }
    return true
  }

  showManeuvers() {
    this.setState({ showResults: !this.state.showResults })
  }

  dateNow() {
    let dtNow = new Date()
    dtNow =
      [dtNow.getMonth() + 1, dtNow.getDate(), dtNow.getFullYear()].join('/') +
      '_' +
      [dtNow.getHours(), dtNow.getMinutes(), dtNow.getSeconds()].join(':')
    return dtNow
  }
  exportToJson = (e) => {
    const { results } = this.props
    const { data } = results[ROUTING_URL]

    e.preventDefault()
    downloadFile({
      data: JSON.stringify(data),
      fileName: 'valhalla-directions_' + this.dateNow() + '.json',
      fileType: 'text/json',
    })
  }

  exportToGeoJson = (e) => {
    const { results } = this.props
    const coordinates = results[ROUTING_URL].data.decodedGeometry

    e.preventDefault()
    downloadFile({
      data: JSON.stringify(L.polyline(coordinates).toGeoJSON()),
      fileName: 'valhalla-directions_' + this.dateNow() + '.geojson',
      fileType: 'text/json',
    })
  }

  render() {
    const { successful, results, cost, cost_details } = this.props
    const length = results[ROUTING_URL].data.distance

    return (
      <Segment
        style={{
          margin: '0 1rem 10px',
          display: successful ? 'block' : 'none',
        }}
      >
        <div className={'flex-column'}>
          <div className={'flex justify-between pointer'}>
            <span className="b">H<sub>2</sub>Transport Cost</span>
            <Popup
              content={'Distance'}
              size={'tiny'}
              trigger={
                <Label allign='right' basic size={'small'} style={{ cursor: 'default' }}>
                  {<>
                    <Icon
                      circular
                      name={'arrows alternate horizontal'}
                      size={'small'}
                    />
                    <div className={'dib v-mid pa1 b f6'}>
                      {`${length && (length.toFixed(length > 100 ? 0 : 1))} km`}
                    </div></>}
                </Label>
              }
            />
            {/* <Summary/> */}
          </div>
          <Divider />
          {length && <>
            <div className='text-right'>
              <h1> {cost}
                <Label allign='right' basic size={'small'} style={{ cursor: 'default' }}>
                  {<>EUR/kgH<sub>2</sub></>

                  }
                </Label>
              </h1>
              <small>{JSON.stringify(cost_details,null,3)}</small>
            </div>
          </>}
          {/* <ContoursInformation provider={H22YOU_URL} /> */}
        </div>
      </Segment>
    )
  }
}

const mapStateToProps = (state) => {
  const { activeTab } = state.common
  const { successful, results, profile, cost, cost_details } = state.transport
  return {
    profile,
    activeTab,
    successful,
    results,
    cost,
    cost_details
  }
}

export default connect(mapStateToProps)(OutputControl)

import React from 'react'
import { Button, Popup, Icon } from 'semantic-ui-react'
import PropTypes from 'prop-types'

export const SettingsButton = ({ handleRemoveProduction }) => {
  return (
    <div>
      <Popup
        content={'Reset production site'}
        size={'tiny'}
        trigger={
          <Button basic icon onClick={handleRemoveProduction}>
            <Icon name="trash" />
          </Button>
        }
      />
    </div>
  )
}

SettingsButton.propTypes = {
  handleRemoveProduction: PropTypes.func,
}

// Common settings

export const transportSettingsInit = {
  transport_profile: 'ammonia_ship',
  throughput: 150,
  pipeline_specific_capex:1200,
  wacc: 10,
  payload: 150,
  speed: 800,
  capex_transport_asset: 250,
  opex_transport_asset: 20,
  hyviate_profile: 'longterm',
  fuel_cost: 0.015,
  fuel_burn: 300,
  turn_around_time: 120,
  burns_payload: false,
  reliability: 95,
  lifetime_transport_asset: 20,
  h2_cost_at_export:2,
  elec_cost_at_export:20,
  heat_cost_at_import:50,
  elec_cost_at_import:100,
  liquefaction_energy:6.6,
  liquefaction_capex:2500
}

export const settingsInitHyviateOverrideShortTerm = {
  ...transportSettingsInit,
  payload: 12,
  speed: 800,
  capex_transport_asset: 25,
  opex_transport_asset: 50,
  hyviate_profile: 'shortterm',
  fuel_cost: 0.02,
  fuel_burn: 120,
  turn_around_time: 60,
  burns_payload: false,
  reliability: 95,
  assetLifeTime: 20
}

export const settingsInitHyviateOverrideMediumTerm = {
  ...transportSettingsInit,
  payload: 35,
  speed: 800,
  capex_transport_asset: 80,
  opex_transport_asset: 25,
  hyviate_profile: 'mediumterm',
  fuel_cost: 0.02,
  fuelBurn: 258,
  turn_around_time: 90,
  burns_payload: false,
  reliability: 95,
  assetLifeTime: 20
}

export const settingsInitHyviateOverrideLongTerm = {
  ...transportSettingsInit,
  payload: 150,
  speed: 800,
  capex_transport_asset: 250,
  opex_transport_asset: 20,
  hyviate_profile: 'longterm',
  fuel_cost: 0.015,
  fuelBurn: 300,
  turn_around_time: 120,
  burns_payload: false,
  reliability: 95,
  assetLifeTime: 20
}

export const settingsPipelineOverride = {
  lifetime_transport_asset: 40,
  transport_profile: 'pipeline',
  throughput:340,
  utilisation:80,
  pipeline_specific_capex:1200,
  pipeline_specific_opex:1,
  h2_as_fuel:false,
  compressor_energy_cost:50,
  compressor_specific_capex:1000,
  compressor_energy_efficiency:70,
  pipeline_diameter:40,
  pipeline_rougness:30,
  pipeline_input_pressure:5,
  pipeline_low_pressure:30,
  pipeline_high_pressure:100,
  pipeline_operating_temperature:20,
  pipeline_stage_length:219,
  seafactor:2,
  wacc:10
}

export const settingsAmmoniaOverride = {
  ...transportSettingsInit,
  transport_profile: 'ammonia_ship',
  throughput:250,
  wacc: 10,
  speed: 30,
  capex_transport_asset: 100,
  opex_transport_asset: 4,
  fuel_cost: 0.02,
  fuel_burn: 2500,
  turn_around_time: 480,
  burns_payload: false,
  lifetime_transport_asset: 20,
  conversion_capex_nh3: 150,
  conversion_energy_nh3:2.9, //GJ/tNH3
  ammonia_payload:53000 ,
  reconversion_energy_nh3:13.6,
  reconversion_capex_nh3:50,
  nh3_h2_Ratio:.122,
  reconversion_heat_nh3:14.4,
  reconversion_electricity_nh3:4.9
}

export const settingsLohcOverride = {
  ...transportSettingsInit,
  transport_profile: 'lohc_ship',
  throughput:250,
  wacc: 10,
  payload_lohc: 110000,
  speed: 30,
  capex_transport_asset: 85,
  opex_transport_asset: 4,
  fuel_cost: 0.02,
  fuel_burn: 3300,
  turn_around_time: 480,
  burns_payload: false,
  lifetime_transport_asset: 20
}

export const settingsLh2Override = {
  ...transportSettingsInit,
  transport_profile: 'lh2_ship',
  throughput:250,
  wacc: 10,
  payload: 11000,
  speed: 30,
  capex_transport_asset: 412,
  opex_transport_asset: 4,
  fuel_cost: 0.02,
  fuel_burn: 187,
  turn_around_time: 480,
  burns_payload: false,
  lifetime_transport_asset: 20
}

const projectVolume = {
  name: 'Project Volume',
  param: 'throughput',
  unit: 'ktonH2 p.a.',
  description: 'Total volume transported on a yearly basis',
  settings: {
    min: 5,
    max: 1000,
    step: 5,
  },
}

const discountRate = {
  name: 'Discount Rate',
  param: 'wacc',
  unit: '%',
  description: 'Cost of capital',
  settings: {
    min: 0,
    max: 20,
    step: .1,
  },
}

const elecCostExport = {
  name: 'Elec. cost @ export',
  param: 'elec_cost_at_export',
  unit: 'EUR/MWh',
  description: 'Cost of electricity at the export location',
  settings: {
    min: 0,
    max: 100,
    step: 1,
  },
}

const elecCostImport = {
  name: 'Elec. cost @ import',
  param: 'elec_cost_at_import',
  unit: 'EUR/MWh',
  description: 'Cost of electricity at the import location',
  settings: {
    min: 0,
    max: 100,
    step: 1,
  },
}

const heatCostImport = {
  name: 'Heat cost @ import',
  param: 'heat_cost_at_import',
  unit: 'EUR/MWh',
  description: 'Cost of heat at the import location',
  settings: {
    min: 0,
    max: 100,
    step: 1,
  },
}

const H2CostAtExport = {
  name: 'H2 cost @ export',
  param: 'h2_cost_at_export',
  unit: 'EUR/kgH2',
  description: 'Cost of H2 at the export location',
  settings: {
    min: 0,
    max: 5,
    step: .1,
  },
}
// Conversion Asset Settings

const liquefactionCapex = {
  name: 'Liquefaction CAPEX',
  param: 'liquefaction_capex',
  unit: 'MEUR/tpd',
  description: 'Value indicates the capex for installed liqefaction capacity',
  settings: {
    min: 0,
    max: 5000,
    step: 1,
  },
}

const liqefactionEnergy = {
  name: 'Liquefaction Energy',
  param: 'liquefaction_energy',
  unit: `kwH/kgH2`,
  description: 'Value indicates the needed (electric) energy for liquefaction',
  settings: {
    min: 0,
    max: 15,
    step: .1,
  },
}

const conversionCapexNH3 = {
  name: 'Conversion Capex',
  param: 'conversion_capex_nh3',
  unit: `kEUR/tNH3`,
  description: 'Value indicates the capital cost for ammonia synthesis plant including air separation unit (ASU) on a ton per day basis.',
  settings: {
    min: 0,
    max: 200,
    step: 1,
  },
}

const conversionEnergyNH3 = {
  name: 'Conversion Energy',
  param: 'conversion_energy_nh3',
  unit: `GJ/tNH3`,
  description: 'Value indicates the needed (electric) energy for ammonia synthesis',
  settings: {
    min: 0,
    max: 10,
    step: .1,
  },
}

const reconversionCapexNH3 = {
  name: 'Reconversion Capex',
  param: 'reconversion_capex_nh3',
  unit: `kEUR/tNH3`,
  description: 'Value indicates the capital cost for ammonia cracking including air separation unit (ASU) on a ton per day basis.',
  settings: {
    min: 0,
    max: 100,
    step: .1,
  },
}

const reconversionHeatNH3 = {
  name: 'Reconversion Heat',
  param: 'reconversion_heat_nh3',
  unit: `MWh/tH2`,
  description: 'Value indicates the needed heat for backcracking.',
  settings: {
    min: 0,
    max: 20,
    step: .1,
  },
}

const reconversionElectricityNH3 = {
  name: 'Reconversion Electricity',
  param: 'reconversion_electricity_nh3',
  unit: `MWh/tH2`,
  description: 'Value indicates the needed electricity for backcracking and conditioning.',
  settings: {
    min: 0,
    max: 1,
    step: .1,
  },
}

// const recoveryRate = {
//   name: 'Recovery Rate',
//   param: 'recovery_rate',
//   unit: `%`,
//   description: 'The rate of H2 versus the input of NH3',
//   settings: {
//     min: 80,
//     max: 100,
//     step: .1,
//   },
// }

const nh3FuelCracking = {
  name: 'NH3 fuel for cracking',
  description:
    'Indicates if transported fuel is used for cracking.',
  param: 'nh3_for_cracking',
}

const payloadNH3 = {
  name: 'Ammonia payload',
  param: 'ammonia_payload',
  unit: `tNH3`,
  description: 'Value indicates total ammonia payload on a vessel.',
  settings: {
    min: 0,
    max: 15,
    step: .1,
  },
}

const payloadLohc = {
  name: 'LOHC Payload',
  param: 'payload_lohc',
  unit: `ton`,
  description: 'Value indicates total LOHC (tolueen or other) payload on a vessel.',
  settings: {
    min: 0,
    max: 150000,
    step: 100,
  },
}

// Transport Asset Settings

const payload = {
  name: 'Payload',
  param: 'payload',
  unit: 'tH2',
  description: 'Total hydrogen onboard the asset',
  settings: {
    min: 0,
    max: 150,
    step: .1,
  },
}

const speed = {
  name: 'Speed',
  param: 'speed',
  unit: 'km/h',
  description: 'Cruising speed',
  settings: {
    min: 0,
    max: 1000,
    step: 1,
  },
}

const capexAsset = {
  name: 'Capex of asset',
  param: 'capex_transport_asset',
  unit: 'mEUR',
  description: 'Capital cost of asset',
  settings: {
    min: 0,
    max: 400,
    step: .1,
  },
}

const opexAsset = {
  name: 'Opex of asset',
  param: 'opex_transport_asset',
  unit: '% of capex',
  description: 'Opex cost of asset',
  settings: {
    min: 0,
    max: 5,
    step: .1,
  },
}

const fuelCost = {
  name: 'Cost of fuel',
  param: 'fuel_cost',
  unit: 'EUR/MJ',
  description: 'Cost of fuel for tansport asset',
  settings: {
    min: 0,
    max: 0.05,
    step: .001,
  },
}

const compressorEnergyEfficency = {
  name: 'Compressor Efficiency',
  param: 'compressor_energy_efficiency',
  unit: '%',
  description: 'Efficiency compressor station',
  settings: {
    min: 0,
    max: 1,
    step: 1,
  },
}

const fuelBurn = {
  name: 'Spec. fuel cons.',
  param: 'fuel_burn',
  unit: 'MJ/km',
  description: 'Fuel consumed en-route',
  settings: {
    min: 0,
    max: 50,
    step: .1,
  },
}

const turnAroundTime = {
  name: 'Turn around Time',
  param: 'turn_around_time',
  unit: 'min.',
  description: 'The time to fuel or defuel the asset',
  settings: {
    min: 0,
    max: 180,
    step: 1,
  },
}

const burnsPayload = {
  name: 'Onboard payload for propulsion',
  description:
    'Indicate if onboard hydrogen is consumed during transport',
  param: 'burns_payload',
}

// const reliability = {
//   name: 'Operational Reliability',
//   param: 'reliability',
//   unit: '%',
//   description: 'Indicate the operational reliability of the asset',
//   settings: {
//     min: 50,
//     max: 100,
//     step: 1,
//   },
// }

const assetLifeTime = {
  name: 'Lifetime of asset',
  param: 'lifetime_transport_asset',
  unit: 'years',
  description: 'Indicate the usefull life of the asset',
  settings: {
    min: 0,
    max: 40,
    step: 1,
  },
}

const utilisation = {
  name: 'Utilisation rate',
  param: 'utilisation',
  unit: '%',
  description: 'Value indicates the load of the pipeline',
  settings: {
    min: 0,
    max: 100,
    step: 1,
  },
}

const pipelineSpecificCapex = {
  name: 'Specific capex',
  param: 'pipeline_specific_capex',
  unit: 'EUR/m',
  description: 'Value indicates the capex for new pipeline builds or upgrade',
  settings: {
    min: 0,
    max: 5000,
    step: 1,
  },
}

const pipelineSpecificOpex = {
  name: 'Specific opex',
  param: 'pipeline_specific_opex',
  unit: '% of capex',
  description: 'Value indicates the opex for new pipeline builds or upgrade vis-a-vis the capex.',
  settings: {
    min: 0,
    max: 5000,
    step: 1,
  },
}

const compressorPowerFromTranportedFuel = {
  name: 'Compressor energy from H2',
  description:
    'Indicate if onboard hydrogen is consumed during transport',
  param: 'h2_as_fuel',
}



const compressorEnergyCost = {
  name: 'Compressor Energy',
  param: 'compressor_energy_cost',
  unit: 'EUR/MWh',
  description: 'Average cost of compressor energy enroute',
  settings: {
    min: 0,
    max: 100,
    step: 1,
  },
}

const compressorCapex = {
  name: 'Capex compressor',
  param: 'compressor_specific_capex',
  unit: 'EUR/kW',
  description: 'Value indicates the specific capex for installed compressor power',
  settings: {
    min: 0,
    max: 5000,
    step: 1,
  },
}

const pipeDiameter = {
  name: 'Diameter',
  param: 'pipeline_diameter',
  unit: 'cm',
  description: 'Value indicates the diameter of the pipeline',
  settings: {
    min: 0,
    max: 150,
    step: 1,
  },
}

const pipeRoughness = {
  name: 'Roughness',
  param: 'pipeline_rougness',
  unit: 'um',
  description: 'Value indicates the capex for installed compressor power',
  settings: {
    min: 0,
    max: 150,
    step: 1,
  },
}

const inputPressure = {
  name: 'Input Pressure',
  param: 'pipeline_input_pressure',
  unit: 'bar',
  description: 'Value indicates the pressure at the output of the production/storage site.',
  settings: {
    min: 0,
    max: 200,
    step: 1,
  },
}


const lowOperatingPressure = {
  name: 'Low Pressure',
  param: 'pipeline_low_pressure',
  unit: 'bar',
  description: 'Value indicates the lower bound of operating pressure',
  settings: {
    min: 0,
    max: 100,
    step: 1,
  },
}


const highOperatingPressure = {
  name: 'High Pressure',
  param: 'pipeline_high_pressure',
  unit: 'bar',
  description: 'Value indicates the lower bound of operating pressure',
  settings: {
    min: 0,
    max: 200,
    step: 1,
  },
}

const operatingTemperature = {
  name: 'Operating Temp.',
  param: 'pipeline_operating_temperature',
  unit: '°C',
  description: 'Operating temperature of the pipeline',
  settings: {
    min: 0,
    max: 50,
    step: 1,
  },
}

const pipeStageLength = {
  name: 'Stage length',
  param: 'pipeline_stage_length',
  unit: 'km',
  description: 'Length in between compressor stations. ',
  settings: {
    min: 0,
    max: 1000,
    step: 1,
  },
}

const offshoreCostFactor = {
  name: 'Offshore Capex Factor',
  param: 'seafactor',
  unit: '-',
  description: 'Factor used in routing offshore/onshore pipeline',
  settings: {
    min: 0,
    max: 5,
    step: .1,
  },
}

const h2Consumed = {
  name: 'H2 from supply consumed for reconversion',
  description:
    'Indicate if supplied hydrogen is consumed during reconversion',
  param: 'h2_consumed',
}

const hyviateProfile = {
  name: 'HYVIATE Timeline',
  description: `This preset indicates HYVIATE timelines for respectively, 2026, 2030 and beyond 2035.`,
  param: 'hyviate_profile',
  enums: [
    {
      key: 'shortterm',
      text: 'shortterm',
      value: 'shortterm',
    },
    {
      key: 'midterm',
      text: 'midterm',
      value: 'midterm',
    },
    {
      key: 'longterm',
      text: 'longterm',
      value: 'longterm',
    }
  ],
}

export const transport_settings = {
  all: {
    numeric:
      [
        projectVolume,
        discountRate,
        H2CostAtExport,
        elecCostExport,
        elecCostImport,
        heatCostImport,
      ],
    boolean: []
  },
  hyviate: {
    boolean: [],
    numeric: [],
    enum: []
  },
  pipeline: {
    boolean: [],
    numeric: [offshoreCostFactor],
    enum: []
  },
  ammonia_ship:{
    boolean: [],
    numeric: [],
    enum: []
  },
  lohc_ship:{
    boolean: [],
    numeric: [],
    enum: []
  },
  lh2_ship:{
    boolean: [],
    numeric: [],
    enum: []
  }
}



export const h2carrier_settings = {transport:{
  hyviate: {
    numeric: [
      // payload,
      // capexAsset,
      // opexAsset,
      // assetLifeTime,
      // fuelCost,
      // fuelBurn,
      // speed,
      // turnAroundTime,
      // reliability
    ],
    boolean: [
      burnsPayload
    ],
    enum: [
      hyviateProfile
    ],
    conversion:'liquefaction'
  },
  pipeline: {
    numeric: [
      utilisation,
      pipelineSpecificCapex,
      pipelineSpecificOpex,
      assetLifeTime,
      compressorEnergyCost,
      compressorCapex,
      compressorEnergyEfficency,
      pipeDiameter,
      inputPressure,
      lowOperatingPressure,
      highOperatingPressure,
      operatingTemperature,
      pipeStageLength,
      pipeRoughness,
    ],
    boolean: [
      compressorPowerFromTranportedFuel
    ],
    enum: [],
  },
  ammonia_ship:{
    numeric: [
      payloadNH3,
      capexAsset,
      opexAsset,
      assetLifeTime,
      fuelCost,
      fuelBurn,
      speed,
      turnAroundTime,
    ],
    boolean: [
      burnsPayload
    ],
    enum: [
      // 
    ],
    conversion:'liquefaction'
  },
  lohc_ship:{
    numeric: [
      payloadLohc,
      capexAsset,
      opexAsset,
      assetLifeTime,
      fuelCost,
      fuelBurn,
      speed,
      turnAroundTime
    ],
    boolean: [
      burnsPayload
    ],
    enum: [
      // 
    ],
  },
  lh2_ship:{
    numeric: [
      payload,
      capexAsset,
      opexAsset,
      assetLifeTime,
      fuelCost,
      fuelBurn,
      speed,
      turnAroundTime
    ],
    boolean: [
      burnsPayload
    ],
    enum: [
      // 
    ],
  }
}, 
conversion: {
  hyviate: { 
    numeric: [
      liquefactionCapex,
      liqefactionEnergy
    ], 
    boolean: [],
    enum: []
  },
  pipeline: { 
    numeric: [
    ], 
    boolean: [
    ], enum: [
    ] 
  },
  ammonia_ship: { 
    numeric: [
      conversionCapexNH3, conversionEnergyNH3], boolean: [], enum: [] },
  lohc_ship: { 
    numeric: [

    ], boolean: [], enum: [] },
  lh2_ship: { 
    numeric: [
      liquefactionCapex, liqefactionEnergy], boolean: [], enum: [] }
}, 
reconversion: {
  hyviate: { numeric: [], boolean: [], enum: [] },
  pipeline: { numeric: [], boolean: [], enum: [] },
  ammonia_ship: { 
    numeric: [
      reconversionCapexNH3,
      reconversionElectricityNH3,
      reconversionHeatNH3,
      ], 
    boolean: [
      nh3FuelCracking
    ], 
    enum: [

    ]
  },
  lohc_ship: {
    numeric: [],
    boolean: [h2Consumed],
    enum: []
  },
  lh2_ship: { 
    numeric: [],
    boolean: [],
  }
}
}

  // ammoniaTanker: {
  //     numeric: [
  //         shipSpeed,
  //         shipCapex,
  //         shipCapacity,
  //         shipOpex,
  //         shipFuelConsumption,
  //         shipBunkering,
  //     ],
  //     boolean: [
  //         reconversion,
  //     ],
  //     numeric_reconversion: [
  //     ]
  // },
  // ammoniaTerminal: {
  //     numeric: [
  //         capexTank,
  //         capacityTank,
  //         annualOpex,
  //         electricityUse]
  // },
  // ammoniaReconversion: {
  //     numeric: [
  //         nH3Capacity,
  //         reconversionPlantCapex,
  //         reconversionPlantOpex,
  //         heatRequired,
  //         plantEnergy,
  //         h2PurificationEnergy,
  //         h2RecoveryRate,
  //         psaRecoveryRate,
  //     ],
  //     boolean: [
  //         useHydrogen
  //     ]

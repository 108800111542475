import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Divider, Dropdown, Popup, Icon } from 'semantic-ui-react'
import Waypoints from './Waypoints'

import { ProfilePicker } from 'components/profile-picker'
import { SettingsButton } from 'components/SettingsButton'
import { SettingsFooter } from 'components/SettingsFooter'
import { Settings } from './settings'

import {
  doAddWaypoint,
  doRemoveWaypoint,
  makeRequest,
  clearRoutes,
  updateDistributionProfile,
  resetDistributionSettings,
} from 'actions/distributionActions'
import {
  // updateProfile,
  doShowSettings,
  // updatePermalink,
  // resetSettings,
} from 'actions/commonActions'

class DistributionControl extends React.Component {
  static propTypes = {
    profile: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    // settings: PropTypes.object,
  }

  handleUpdateProfile = (event, data) => {
    const { dispatch } = this.props
    // console.log(data)
    dispatch(updateDistributionProfile({ profile: data.selected_profile }))
    dispatch(resetDistributionSettings())
    // dispatch(updatePermalink())
  }

  handleAddWaypoint = (event, data) => {
    const { dispatch } = this.props
    dispatch(doAddWaypoint())
  }

  handleRemoveWaypoints = () => {
    const { dispatch } = this.props
    dispatch(doRemoveWaypoint())
    dispatch(clearRoutes())
  }

  componentDidUpdate = (prevProps) => { }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dispatch } = this.props
    if (this.props.profile !== nextProps.profile) {
      dispatch(makeRequest())
    }
  }

  handleSettings = () => {
    const { dispatch } = this.props
    dispatch(doShowSettings())
  }

  handleDownstreamChange = () => {
    const { dispatch } = this.props
    dispatch()
  }

  render() {
    const { profile, loading } = this.props
    const downstreamOptions = [
      {
        key: 'Industry',
        text: 'Industry',
        value: 'Industry',
      },
      {
        key: 'Mobility 700 bar',
        text: 'Mobility 700 bar',
        value: 'Mobility 700 bar',
      },
      {
        key: 'Mobility 350 bar',
        text: 'Mobility 350 bar',
        value: 'Mobility 350 bar',
      },
      {
        key: 'Mobility LH2 or CCG2',
        text: 'Mobility LH2 or CCG2',
        value: 'Mobility LH2 or CCG2',
      },
    ]
    return (
      <React.Fragment>
        <div className="flex flex-column content-between">
          <div>
            <div className="pa2 flex flex-row justify-between">
              <ProfilePicker
                group={'distribution'}
                profiles={[
                  'pipeline',
                  'gh2_truck',
                  'lh2_truck',
                  'ammonia_truck',
                  'lohc_truck',
                ]}
                loading={loading}
                popupContent={[
                  'Pipeline',
                  'Gaseous H2 Truck (tube trailer)',
                  'Liquid H2 Truck',
                  'NH3 Truck',
                  'LOHC Truck',
                ]}
                activeProfile={profile}
                handleUpdateProfile={this.handleUpdateProfile}
              />
              <SettingsButton handleSettings={this.handleSettings} />
            </div>
            <div className="flex flex-wrap justify-between">
              <Waypoints />
              <Dropdown
                placeholder="Select Downstream Application"
                fluid
                onChange={this.handleDownstreamChange}
                // value={}
                selection
                name={'downStreamApplication'}
                options={downstreamOptions}
              />
            </div>
            <div
              style={{
                marginLeft: 'auto',
              }}
            >
              <Popup
                content={'Downstream application defines product grade requriements linked to the transportation mode.'}
                size={'tiny'}
                trigger={
                  <Icon color="grey" name="help circle" />
                }
              />
            </div>
            <div className="pa2 flex flex-wrap justify-between">
              <Settings
                handleAddWaypoint={this.handleAddWaypoint}
                handleRemoveWaypoints={this.handleRemoveWaypoints}
              />
            </div>
          </div>
          <Divider fitted />
          <SettingsFooter />
        </div>
      </React.Fragment>
    )
  }
}

const mapStateToProps = (state) => {
  const { loading } = state.common
  const { profile, settings } = state.distribution
  return {
    profile,
    loading,
    settings
  }
}

export default connect(mapStateToProps)(DistributionControl)

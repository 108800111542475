export const ROUTING_URL = process.env.REACT_APP_ROUTING
import { VALHALLA_OSM_URL } from './valhalla'

// INTERFACES to be defined/Frozen!!!
export const buildDistributionCostRequest = ({
 results, profile
}) => {
      return {
        json: {"profile":profile,
        "distance":results[VALHALLA_OSM_URL].data.trip.summary.length}
      }
    }
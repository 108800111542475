import axios from 'axios'
import {
  ADD_WAYPOINT,
  CLEAR_WAYPOINTS,
  RECEIVE_GEOCODE_RESULTS,
  REQUEST_GEOCODE_RESULTS,
  SET_WAYPOINT,
  UPDATE_TEXTINPUT,
  EMPTY_WAYPOINT,
  INSERT_WAYPOINT,
  CLEAR_ROUTES,
  TOGGLE_PROVIDER_ISO,
  HIGHLIGHT_MNV,
  ZOOM_TO_MNV,
  UPDATE_INCLINE_DECLINE,
  UPDATE_TRANSPORT_SETTINGS,
  RECEIVE_TRANSPORT_RESULTS,
  RECEIVE_COST_RESULTS,
  UPDATE_TRANSPORT_PROFILE,
  RESET_TRANSPORT_SETTINGS,
  UPDATE_HYVIATE_PROFILE,
  UPDATE_SPECIFIC_CAPEX
} from './types'
import {
  reverse_geocode,
  forward_geocode,
  parseGeocodeResponse,
} from 'utils/nominatim'
import {
  VALHALLA_OSM_URL,
  // buildDirectionsRequest,
  // parseDirectionsGeometry,
} from 'utils/valhalla'
import { 
  H22YOU_URL, 
  buildRouteRequest, 
  parseTransportGeometry,
  buildCostRequest,
  ROUTING_URL, 
  // buildCostRequest
 } from 'utils/transport'
import {
  sendMessage,
  showLoading,
  updatePermalink,
  zoomTo,
} from './commonActions'
// import { h2carrier_settings, 
//   // transport_settings
//  } from 'Controls/Transport/transport-options'
const serverMapping = {
  [VALHALLA_OSM_URL]: 'OSM',
  [H22YOU_URL]: 'Costing Server',
  [ROUTING_URL]: 'Routing server'
}

export const makeRequest = () => (dispatch, getState) => {
  dispatch(updatePermalink())
  const state =getState()
  const { waypoints } = getState().transport
  const { profile } = getState().transport
  const { settings} = getState().transport
  // if 2 results are selected
  const coords = state.common.settings.exclude_polygons[0]
  const activeWaypoints = getActiveWaypoints(waypoints)
  if (activeWaypoints.length >= 2) {
    // settings = filterProfileSettings(profile, settings)
    const routeRequest = buildRouteRequest({
      profile,
      activeWaypoints,
      coords,
      settings
    })
    dispatch(fetchTransportRoute(routeRequest,profile))
  }
}

export const makeCostRequest = () => (dispatch, getState) => {
  // dispatch(updatePermalink())
  // const { waypoints } = getState().transport
  const { profile } = getState().transport
  const { settings } = getState().transport
  const { results} = getState().transport
  // if 2 results are selected
  // const activeWaypoints = getActiveWaypoints(waypoints)
  if (results[ROUTING_URL].data.distance) {
    // settings = filterProfileSettings(profile, settings)
    const costRequest = buildCostRequest({
      settings,
      results,
      profile,
    })
    dispatch(fetchCost(costRequest,profile))
  }
}

export const makeSpecificCapexRequest = () => (dispatch, getState) => {
  // dispatch(updatePermalink())
  // const { waypoints } = getState().transport
  const { profile } = getState().transport
  const { settings } = getState().transport
  const { results} = getState().transport
  // if 2 results are selected
  // const activeWaypoints = getActiveWaypoints(waypoints)
  if (results[ROUTING_URL].data.distance) {
    // settings = filterProfileSettings(profile, settings)
    const costRequest = buildCostRequest({
      settings,
      results,
      profile,
    })
    dispatch(fetchSpecificCapex(costRequest,profile))
  }
}

const getActiveWaypoints = (waypoints) => {
  const activeWaypoints = []
  for (const waypoint of waypoints) {
    if (waypoint.geocodeResults.length > 0) {
      for (const result of waypoint.geocodeResults) {
        if (result.selected) {
          activeWaypoints.push(result)
          break
        }
      }
    }
  }
  return activeWaypoints
}

const fetchCost = (costRequest, profile) => (dispatch) => {
  dispatch(showLoading(true))
  const destination = '/' + profile + '/all'
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  axios
    .post(H22YOU_URL + destination,JSON.stringify(costRequest.json), config)
    .then(({ data }) => {
      // data.decodedGeometry = parseTransportGeometry(data)
      // console.log(data)
      dispatch(registerCostResponse(data))
      // dispatch(zoomTo(data.decodedGeometry))
    })
    .catch(({ response }) => {
      let error_msg = response.data.error
      if (response.data.error_code === 154) {
        error_msg += ` for ${costRequest.json.costing}.`
      }
      // dispatch(clearRoutes(H22YOU_URL))
      dispatch(
        sendMessage({
          type: 'warning',
          icon: 'warning',
          description: `${serverMapping[H22YOU_URL]}: ${error_msg}`,
          title: `${response.data.status}`,
        })
      )
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(showLoading(false))
      }, 500)
    })}

const fetchTransportRoute = (h22youRequest, profile) => (dispatch) => {
  dispatch(showLoading(true))
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  axios
    .post(ROUTING_URL,JSON.stringify(h22youRequest.json), config)
    .then(({ data }) => {
      data.decodedGeometry = parseTransportGeometry(data)
      dispatch(registerRouteResponse(ROUTING_URL, data),300)
      dispatch(zoomTo(data.decodedGeometry))
    })
    .catch(({ response }) => {
      let error_msg = response.data.error
      if (response.data.error_code === 154) {
        error_msg += ` for ${h22youRequest.json.costing}.`
      }
      // dispatch(clearRoutes(H22YOU_URL))
      dispatch(
        sendMessage({
          type: 'warning',
          icon: 'warning',
          description: `${serverMapping[ROUTING_URL]}: ${error_msg}`,
          title: `${response.data.status}`,
        })
      )
    })
    .finally(() => {
      if (profile==='ammonia_ship' || profile==='lohc_ship'|| profile==='lh2_ship'){
        dispatch(sendMessage({
          type: 'warning',
          icon: 'warning',
          description: `Cost does not include overland distribution`,
          title: `Cost does not include overland distribution`,
        }))
        dispatch(sendMessage({
          type: 'info',
          icon: 'info',
          description: `Searouting can be improved with drawing a polygon around capes if needed`,
          title: `Searouting can be improved with drawing a polygon around capes if needed`,
        }))
      }
      setTimeout(() => {
        dispatch(showLoading(false))
      }, 500)
    })
}

export const fetchSpecificCapex = (costRequest, profile) => (dispatch) => {
  dispatch(showLoading(true))
  const destination = '/' + profile + '/diameter'
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  axios
    .post(H22YOU_URL + destination,JSON.stringify(costRequest.json), config)
    .then(({ data }) => {
      // data.decodedGeometry = parseTransportGeometry(data)
      // console.log(data)
      dispatch(updateSpecificCapex(data))
      // dispatch(zoomTo(data.decodedGeometry))
    })
    .catch(({ response }) => {
      let error_msg = response.data.error
      if (response.data.error_code === 154) {
        error_msg += ` for ${costRequest.json.costing}.`
      }
      dispatch(
        sendMessage({
          type: 'warning',
          icon: 'warning',
          description: `${serverMapping[H22YOU_URL]}: ${error_msg}`,
          title: `${response.data.status}`,
        })
      )
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(showLoading(false))
      }, 500)
    })}

export const updateTransportSettings = (object) => ({
  type: UPDATE_TRANSPORT_SETTINGS,
  payload: object,
})

// const fetchValhallaDirections = (valhallaRequest) => (dispatch) => {
//   dispatch(showLoading(true))

//   const config = {
//     params: { json: JSON.stringify(valhallaRequest.json) },
//     headers: {
//       'Content-Type': 'application/json',
//     },
//   }
//   axios
//     .get(VALHALLA_OSM_URL + '/route', config)
//     .then(({ data }) => {
//       data.decodedGeometry = parseDirectionsGeometry(data)
//       dispatch(registerRouteResponse(VALHALLA_OSM_URL, data))
//       dispatch(zoomTo(data.decodedGeometry))
//     })
//     .catch(({ response }) => {
//       let error_msg = response.data.error
//       if (response.data.error_code === 154) {
//         error_msg += ` for ${valhallaRequest.json.costing}.`
//       }
//       dispatch(clearRoutes(VALHALLA_OSM_URL))
//       dispatch(
//         sendMessage({
//           type: 'warning',
//           icon: 'warning',
//           description: `${serverMapping[VALHALLA_OSM_URL]}: ${error_msg}`,
//           title: `${response.data.status}`,
//         })
//       )
//     })
//     .finally(() => {
//       setTimeout(() => {
//         dispatch(showLoading(false))
//       }, 500)
//     })
// }

export const registerCostResponse = (data) => ({
  type: RECEIVE_COST_RESULTS,
  payload: {
    data
  }
})

export const updateSpecificCapex = (data) => ({
  type: UPDATE_SPECIFIC_CAPEX,
  payload: {
    data
  }
})


export const registerRouteResponse = (provider, data) => ({
  type: RECEIVE_TRANSPORT_RESULTS,
  payload: {
    provider,
    data,
  },
})

export const clearRoutes = (provider) => ({
  type: CLEAR_ROUTES,
  payload: provider,
})

const placeholderAddress = (index, lng, lat) => (dispatch) => {
  // placeholder until geocoder is complete
  // will add latLng to input field
  const addresses = [
    {
      title: '',
      displaylnglat: [lng, lat],
      key: index,
      addressindex: index,
    },
  ]
  dispatch(receiveGeocodeResults({ addresses, index: index }))
  dispatch(
    updateTextInput({
      inputValue: [lng.toFixed(6), lat.toFixed(6)].join(', '),
      index: index,
      addressindex: 0,
    })
  )
}

export const fetchReverseGeocodePerma = (object) => (dispatch) => {
  dispatch(requestGeocodeResults({ index: object.index, reverse: true }))

  const { index } = object
  const { permaLast } = object
  const { lng, lat } = object.latLng

  if (index > 1) {
    dispatch(doAddWaypoint(true, permaLast))
  }

  reverse_geocode(lng, lat)
    .then((response) => {
      dispatch(
        processGeocodeResponse(
          response.data,
          index,
          true,
          [lng, lat],
          permaLast
        )
      )
    })
    .catch((error) => {
      console.log(error) //eslint-disable-line
    })
  // .finally(() => {
  //   // always executed
  // })
}

export const fetchReverseGeocode = (object) => (dispatch, getState) => {
  dispatch(requestGeocodeResults({ index: object.index, reverse: true }))
  const { waypoints } = getState().transport

  let { index } = object
  const { fromDrag } = object
  const { lng, lat } = object.latLng

  if (index === -1) {
    index = waypoints.length - 1
  } else if (index === 1 && !fromDrag) {
    // insert waypoint from context menu
    dispatch(doAddWaypoint(true))

    index = waypoints.length - 2
  }

  dispatch(placeholderAddress(index, lng, lat))

  dispatch(requestGeocodeResults({ index, reverse: true }))

  reverse_geocode(lng, lat)
    .then((response) => {
      dispatch(processGeocodeResponse(response.data, index, true, [lng, lat]))
    })
    .catch((error) => {
      console.log(error) //eslint-disable-line
    })
  // .finally(() => {
  //   // always executed
  // })
}

export const fetchGeocode = (object) => (dispatch) => {
  if (object.lngLat) {
    const addresses = [
      {
        title: object.lngLat.toString(),
        description: '',
        selected: false,
        addresslnglat: object.lngLat,
        sourcelnglat: object.lngLat,
        displaylnglat: object.lngLat,
        key: object.index,
        addressindex: 0,
      },
    ]
    dispatch(receiveGeocodeResults({ addresses, index: object.index }))
  } else {
    dispatch(requestGeocodeResults({ index: object.index }))

    forward_geocode(object.inputValue)
      .then((response) => {
        dispatch(processGeocodeResponse(response.data, object.index))
      })
      .catch((error) => {
        console.log(error) //eslint-disable-line
      })
      .finally(() => {})
  }
}

const processGeocodeResponse =
  (data, index, reverse, lngLat, permaLast) => (dispatch) => {
    const addresses = parseGeocodeResponse(data, lngLat)
    // if no address can be found
    if (addresses.length === 0) {
      dispatch(
        sendMessage({
          type: 'warning',
          icon: 'warning',
          description: 'Sorry, no addresses can be found.',
          title: 'No addresses',
        })
      )
      // for (const results of document.getElementsByClassName('results')) {
      //   results.classList.remove('visible')
      // }
    }
    dispatch(receiveGeocodeResults({ addresses, index }))

    if (reverse) {
      dispatch(
        updateTextInput({
          inputValue: addresses[0].title,
          index: index,
          addressindex: 0,
        })
      )
      if (permaLast === undefined) {
        dispatch(makeRequest())
        dispatch(updatePermalink())
      } else if (permaLast) {
        dispatch(makeRequest())
        dispatch(updatePermalink())
      }
    }
  }

export const receiveGeocodeResults = (object) => ({
  type: RECEIVE_GEOCODE_RESULTS,
  payload: object,
})

export const requestGeocodeResults = (object) => ({
  type: REQUEST_GEOCODE_RESULTS,
  payload: object,
})

export const updateTextInput = (object) => ({
  type: UPDATE_TEXTINPUT,
  payload: object,
})

export const doRemoveWaypoint = (index) => (dispatch, getState) => {
  if (index === undefined) {
    dispatch(clearWaypoints())
    Array(2)
      .fill()
      .map((_, i) => dispatch(doAddWaypoint()))
  } else {
    let waypoints = getState().transport.waypoints
    if (waypoints.length > 2) {
      dispatch(clearWaypoints(index))
      dispatch(makeRequest())
    } else {
      dispatch(emptyWaypoint(index))
    }
    waypoints = getState().transport.waypoints
    if (getActiveWaypoints(waypoints).length < 2) {
      dispatch(clearRoutes(ROUTING_URL))
    }
  }
  dispatch(updatePermalink())
}

export const highlightManeuver = (fromTo) => (dispatch, getState) => {
  const highlightSegment = getState().transport.highlightSegment
  // this is dehighlighting
  if (
    highlightSegment.startIndex === fromTo.startIndex &&
    highlightSegment.endIndex === fromTo.endIndex
  ) {
    fromTo.startIndex = -1
    fromTo.endIndex = -1
  }

  dispatch({
    type: HIGHLIGHT_MNV,
    payload: fromTo,
  })
}

export const zoomToManeuver = (zoomObj) => ({
  type: ZOOM_TO_MNV,
  payload: zoomObj,
})

export const clearWaypoints = (index) => ({
  type: CLEAR_WAYPOINTS,
  payload: { index: index },
})

export const emptyWaypoint = (index) => ({
  type: EMPTY_WAYPOINT,
  payload: { index: index },
})

export const updateInclineDeclineTotal = (object) => ({
  type: UPDATE_INCLINE_DECLINE,
  payload: object,
})

export const doAddWaypoint = (doInsert) => (dispatch, getState) => {
  const waypoints = getState().transport.waypoints
  let maxIndex = Math.max.apply(
    Math,
    waypoints.map((wp) => {
      return wp.id
    })
  )
  maxIndex = isFinite(maxIndex) === false ? 0 : maxIndex + 1

  const emptyWp = {
    id: maxIndex.toString(),
    geocodeResults: [],
    isFetching: false,
    userInput: '',
  }
  if (doInsert) {
    dispatch(insertWaypoint(emptyWp))
  } else {
    dispatch(addWaypoint(emptyWp))
  }
}

const insertWaypoint = (waypoint) => ({
  type: INSERT_WAYPOINT,
  payload: waypoint,
})

export const addWaypoint = (waypoint) => ({
  type: ADD_WAYPOINT,
  payload: waypoint,
})

export const setWaypoints = (waypoints) => ({
  type: SET_WAYPOINT,
  payload: waypoints,
})

export const showProvider = (provider, show) => ({
  type: TOGGLE_PROVIDER_ISO,
  payload: {
    provider,
    show,
  },
})


export const filterProfileSettings = (profile, settings) => {
  // const filteredSettings = {
  //   exclude_polygons: settings.exclude_polygons,
  // }
  // for (const setting in settings) {
    // for (const item of transport_settings[profile].numeric) {
    //   if (setting === item.param) {
    //     filteredSettings[setting] = settings[setting]
    //   }
    // }
    // for (const item of transport_settings[profile].boolean) {
    //   if (setting === item.param) {
    //     filteredSettings[setting] = settings[setting]
    //   }
    // }
    // for (const item of transport_settings[profile].enum) {
    //   if (setting === item.param) {
    //     filteredSettings[setting] = settings[setting]
    //   }
    // }
    // for (const item of h2carrier_settings[profile].numeric) {
    //   if (setting === item.param) {
    //     filteredSettings[setting] = settings[setting]
    //   }
    }
    // for (const item of h2carrier_settings[profile].boolean) {
    //   if (setting === item.param) {
    //     filteredSettings[setting] = settings[setting]
    //   }
    // }
    // for (const item of h2carrier_settings[profile].enum) {
    //   if (setting === item.param) {
    //     filteredSettings[setting] = settings[setting]
    //   }
    // }
  // }
  // return filteredSettings
// }

export const updateTransportProfile = (profile) => ({
  type: UPDATE_TRANSPORT_PROFILE,
  payload: profile,
})

export const resetTransportSettings = (profile) => ({
  type: RESET_TRANSPORT_SETTINGS,
  payload: profile
})


export const updateHyviate = (hyviate_profile) => ({
  type: UPDATE_HYVIATE_PROFILE,
  payload: hyviate_profile
})

import {
  ADD_WAYPOINT_DISTRIBUTION,
  INSERT_WAYPOINT_DISTRIBUTION,
  SET_WAYPOINT_DISTRIBUTION,
  CLEAR_WAYPOINTS_DISTRIBUTION,
  EMPTY_WAYPOINT_DISTRIBUTION,
  UPDATE_TEXTINPUT_DISTRIBUTION,
  REQUEST_GEOCODE_RESULTS_DISTRIBUTION,
  RECEIVE_GEOCODE_RESULTS_DISTRIBUTION,
  RECEIVE_ROUTE_RESULTS_DISTRIBUTION,
  CLEAR_ROUTES_DISTRIBUTION,
  TOGGLE_PROVIDER_ISO_DISTRIBUTION,
  HIGHLIGHT_MNV_DISTRIBUTION,
  ZOOM_TO_MNV_DISTRIBUTION,
  UPDATE_INCLINE_DECLINE_DISTRIBUTION,
  RESET_DISTRIBUTION_SETTINGS,
  UPDATE_DISTRIBUTION_PROFILE,
  RECEIVE_DISTRIBUTION_COST_RESULTS
} from 'actions/types'

import { VALHALLA_OSM_URL } from '../utils/valhalla'

const initialState = {
  profile: 'gh2_truck',
  successful: false,
  highlightSegment: {
    startIndex: -1,
    endIndex: -1,
  },
  waypoints: [],
  zoomObj: {
    index: -1,
    timeNow: -1,
  },
  selectedAddresses: '',
  results: {
    [VALHALLA_OSM_URL]: {
      data: {},
      show: true,
    },
  },
}

export const distribution = (state = initialState, action) => {
  // console.log(action) //eslint-disable-line
  switch (action.type) {
    case UPDATE_INCLINE_DECLINE_DISTRIBUTION:
      return {
        ...state,
        inclineDeclineTotal: { ...action.payload },
      }

    case TOGGLE_PROVIDER_ISO_DISTRIBUTION:
      return {
        ...state,
        results: {
          ...state.results,
          [action.payload.provider]: {
            ...state.results[action.payload.provider],
            show: action.payload.show,
          },
        },
      }

    case CLEAR_ROUTES_DISTRIBUTION:
      return {
        ...state,
        successful: false,
        inclineDeclineTotal: undefined,
        results: {
          ...state.results,
          [action.payload]: {
            ...state.results[action.payload],
            data: {},
          },
        },
      }

    case RECEIVE_ROUTE_RESULTS_DISTRIBUTION:
      return {
        ...state,
        inclineDeclineTotal: undefined,
        results: {
          ...state.results,
          [action.payload.provider]: {
            ...state.results[action.payload.provider],
            data: action.payload.data,
          },
        },
        successful: true,
      }

    case RECEIVE_GEOCODE_RESULTS_DISTRIBUTION:
      return {
        ...state,
        waypoints: state.waypoints.map((waypoint, i) =>
          i === action.payload.index
            ? {
              ...waypoint,
              isFetching: false,
              geocodeResults: action.payload.addresses,
            }
            : waypoint
        ),
      }

    case REQUEST_GEOCODE_RESULTS_DISTRIBUTION:
      return {
        ...state,
        waypoints: state.waypoints.map((waypoint, i) =>
          i === action.payload.index
            ? { ...waypoint, isFetching: true }
            : waypoint
        ),
      }

    case UPDATE_TEXTINPUT_DISTRIBUTION:
      // Catch array of selectedAddress from all waypoints
      // eslint-disable-next-line no-case-declarations
      const selectedAddresses = []
      state.waypoints.forEach((waypoint) => {
        waypoint.geocodeResults.forEach((result, i) => {
          selectedAddresses.push(
            i === action.payload.addressindex ? waypoint : null
          )
        })
      })
      return {
        ...state,
        selectedAddresses: selectedAddresses,
        waypoints: state.waypoints.map((waypoint, i) =>
          i === action.payload.index
            ? {
              ...waypoint,
              userInput: action.payload.inputValue,
              geocodeResults: waypoint.geocodeResults.map((result, i) =>
                i === action.payload.addressindex
                  ? { ...result, selected: true }
                  : { ...result, selected: false }
              ),
            }
            : waypoint
        ),
      }

    case CLEAR_WAYPOINTS_DISTRIBUTION: {
      return {
        ...state,
        waypoints:
          action.payload.index >= 0
            ? state.waypoints.filter((v, i) => i !== action.payload.index)
            : [],
      }
    }

    case EMPTY_WAYPOINT_DISTRIBUTION: {
      return {
        ...state,
        waypoints: state.waypoints.map((waypoint, i) =>
          i === action.payload.index
            ? {
              ...waypoint,
              userInput: '',
              geocodeResults: [],
            }
            : waypoint
        ),
      }
    }

    case SET_WAYPOINT_DISTRIBUTION: {
      return {
        ...state,
        waypoints: action.payload,
      }
    }

    case ADD_WAYPOINT_DISTRIBUTION: {
      return {
        ...state,
        waypoints: [...state.waypoints, action.payload],
      }
    }

    case INSERT_WAYPOINT_DISTRIBUTION: {
      const waypoints = state.waypoints
      waypoints.splice(waypoints.length - 1, 0, action.payload)

      return {
        ...state,
        waypoints: [...waypoints],
      }
    }

    case HIGHLIGHT_MNV_DISTRIBUTION: {
      return {
        ...state,
        highlightSegment: action.payload,
      }
    }

    case ZOOM_TO_MNV_DISTRIBUTION: {
      return {
        ...state,
        zoomObj: action.payload,
      }
    }

    case RESET_DISTRIBUTION_SETTINGS: {
      // console.log('reset')
      // console.log(action.payload)
      // return {
      //   ...state,
      //   profile: action.payload,
      //   settings: { ...initialState }
      // }
      return state
    }

    case UPDATE_DISTRIBUTION_PROFILE: {
      const { profile } = action.payload
      return {
        ...state,
        profile,
      }
    }

    case RECEIVE_DISTRIBUTION_COST_RESULTS: {
      console.log(action.payload)
      return {
        ...state,
        cost:action.payload.data.cost.toFixed(2)
      }
    }

    default: {
      return state
    }
  }
}

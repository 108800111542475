import axios from 'axios'
import {
  ADD_WAYPOINT_DISTRIBUTION,
  CLEAR_WAYPOINTS_DISTRIBUTION,
  RECEIVE_GEOCODE_RESULTS_DISTRIBUTION,
  REQUEST_GEOCODE_RESULTS_DISTRIBUTION,
  SET_WAYPOINT_DISTRIBUTION,
  // UPDATE_TEXTINPUT,
  EMPTY_WAYPOINT_DISTRIBUTION,
  INSERT_WAYPOINT_DISTRIBUTION,
  RECEIVE_ROUTE_RESULTS_DISTRIBUTION,
  CLEAR_ROUTES_DISTRIBUTION,
  TOGGLE_PROVIDER_ISO_DISTRIBUTION,
  HIGHLIGHT_MNV_DISTRIBUTION,
  ZOOM_TO_MNV_DISTRIBUTION,
  UPDATE_INCLINE_DECLINE_DISTRIBUTION,
  UPDATE_TEXTINPUT_DISTRIBUTION,
  UPDATE_DISTRIBUTION_PROFILE,
  RESET_DISTRIBUTION_SETTINGS,
  RECEIVE_DISTRIBUTION_COST_RESULTS
} from './types'
import {
  reverse_geocode,
  forward_geocode,
  parseGeocodeResponse,
} from 'utils/nominatim'
import {
  VALHALLA_OSM_URL,
  buildDirectionsRequest,
  parseDirectionsGeometry,
} from 'utils/valhalla'
import {
  buildDistributionCostRequest
} from 'utils/distribution'
import {
  sendMessage,
  showLoading,
  // filterProfileSettings,
  updatePermalink,
  zoomTo,
} from './commonActions'
import { H22YOU_URL } from 'utils/transport'

const serverMapping = {
  [VALHALLA_OSM_URL]: 'VALHALLA',
  [H22YOU_URL]: "Costing Server"
}

export const makeRequest = () => (dispatch, getState) => {
  dispatch(updatePermalink())
  const { waypoints } = getState().distribution
  const { profile } = getState().distribution
  const { settings } = getState().distribution
  // if 2 results are selected
  const activeWaypoints = getActiveWaypoints(waypoints)
  if (activeWaypoints.length >= 2) {
    // settings = filterProfileSettings(profile, settings)

    const valhallaRequest = buildDirectionsRequest({
      profile,
      activeWaypoints,
      settings,
    })
    dispatch(fetchValhallaDirections(valhallaRequest))
  }
}

export const makeDistributionCostRequest = () => (dispatch, getState) => {
  // dispatch(updatePermalink())
  // const { waypoints } = getState().transport
  const { profile } = getState().distribution
  const { results} = getState().distribution
  // if 2 results are selected
  // const activeWaypoints = getActiveWaypoints(waypoints)
  if (results[VALHALLA_OSM_URL].data.trip) {
    // settings = filterProfileSettings(profile, settings)
    const costRequest = buildDistributionCostRequest({
      results,
      profile,
    })
    dispatch(fetchDistributionCost(costRequest))
  }
}

const fetchDistributionCost = (costRequest) => (dispatch) => {
  dispatch(showLoading(true))
  const destination = '/distribution/simplified'
  const config = {
    headers: {
      'Content-Type': 'application/json',
    },
  }
  axios
    .post(H22YOU_URL + destination,JSON.stringify(costRequest.json), config)
    .then(({ data }) => {
      // data.decodedGeometry = parseTransportGeometry(data)
      // console.log(data)
      dispatch(registerDistributionCostResponse(data))
      // dispatch(zoomTo(data.decodedGeometry))
    })
    .catch(({ response }) => {
      let error_msg = response.data.error
      if (response.data.error_code === 154) {
        error_msg += ` for ${costRequest.json.costing}.`
      }
      // dispatch(clearRoutes(H22YOU_URL))
      dispatch(
        sendMessage({
          type: 'warning',
          icon: 'warning',
          description: `${serverMapping[H22YOU_URL]}: ${error_msg}`,
          title: `${response.data.status}`,
        })
      )
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(showLoading(false))
      }, 500)
    })}

const getActiveWaypoints = (waypoints) => {
  const activeWaypoints = []
  for (const waypoint of waypoints) {
    if (waypoint.geocodeResults.length > 0) {
      for (const result of waypoint.geocodeResults) {
        if (result.selected) {
          activeWaypoints.push(result)
          break
        }
      }
    }
  }
  return activeWaypoints
}

const fetchValhallaDirections = (valhallaRequest) => (dispatch) => {
  dispatch(showLoading(true))

  const config = {
    params: { json: JSON.stringify(valhallaRequest.json) },
    headers: {
      'Content-Type': 'application/json',
    },
  }
  axios
    .get(VALHALLA_OSM_URL + '/route', config)
    .then(({ data }) => {
      data.decodedGeometry = parseDirectionsGeometry(data)
      dispatch(registerRouteResponse(VALHALLA_OSM_URL, data))
      dispatch(zoomTo(data.decodedGeometry))
    })
    .catch(({ response }) => {
      let error_msg = response.data.error
      if (response.data.error_code === 154) {
        error_msg += ` for ${valhallaRequest.json.costing}.`
      }
      dispatch(clearRoutes(VALHALLA_OSM_URL))
      dispatch(
        sendMessage({
          type: 'warning',
          icon: 'warning',
          description: `${serverMapping[VALHALLA_OSM_URL]}: ${error_msg}`,
          title: `${response.data.status}`,
        })
      )
    })
    .finally(() => {
      setTimeout(() => {
        dispatch(showLoading(false))
      }, 500)
    })
}

export const registerRouteResponse = (provider, data) => ({
  type: RECEIVE_ROUTE_RESULTS_DISTRIBUTION,
  payload: {
    provider,
    data,
  },
})

export const clearRoutes = (provider) => ({
  type: CLEAR_ROUTES_DISTRIBUTION,
  payload: provider,
})

const placeholderAddress = (index, lng, lat) => (dispatch) => {
  // placeholder until geocoder is complete
  // will add latLng to input field
  const addresses = [
    {
      title: '',
      displaylnglat: [lng, lat],
      key: index,
      addressindex: index,
    },
  ]
  dispatch(receiveGeocodeResults({ addresses, index: index }))
  dispatch(
    updateTextInput({
      inputValue: [lng.toFixed(6), lat.toFixed(6)].join(', '),
      index: index,
      addressindex: 0,
    })
  )
}

export const fetchReverseGeocodePerma = (object) => (dispatch) => {
  dispatch(requestGeocodeResults({ index: object.index, reverse: true }))

  const { index } = object
  const { permaLast } = object
  const { lng, lat } = object.latLng

  if (index > 1) {
    dispatch(doAddWaypoint(true, permaLast))
  }

  reverse_geocode(lng, lat)
    .then((response) => {
      dispatch(
        processGeocodeResponse(
          response.data,
          index,
          true,
          [lng, lat],
          permaLast
        )
      )
    })
    .catch((error) => {
      console.log(error) //eslint-disable-line
    })
  // .finally(() => {
  //   // always executed
  // })
}

export const fetchReverseGeocodeDistribution = (object) => (dispatch, getState) => {
  //dispatch(requestGeocodeResults({ index: object.index, reverse: true }))
  const { waypoints } = getState().distribution

  let { index } = object
  const { fromDrag } = object
  const { lng, lat } = object.latLng

  if (index === -1) {
    index = waypoints.length - 1
  } else if (index === 1 && !fromDrag) {
    // insert waypoint from context menu
    dispatch(doAddWaypoint(true))

    index = waypoints.length - 2
  }

  dispatch(placeholderAddress(index, lng, lat))

  dispatch(requestGeocodeResults({ index, reverse: true }))

  reverse_geocode(lng, lat)
    .then((response) => {
      dispatch(processGeocodeResponse(response.data, index, true, [lng, lat]))
    })
    .catch((error) => {
      console.log(error) //eslint-disable-line
    })
  // .finally(() => {
  //   // always executed
  // })
}

export const fetchGeocode = (object) => (dispatch) => {
  if (object.lngLat) {
    const addresses = [
      {
        title: object.lngLat.toString(),
        description: '',
        selected: false,
        addresslnglat: object.lngLat,
        sourcelnglat: object.lngLat,
        displaylnglat: object.lngLat,
        key: object.index,
        addressindex: 0,
      },
    ]
    dispatch(receiveGeocodeResults({ addresses, index: object.index }))
  } else {
    dispatch(requestGeocodeResults({ index: object.index }))

    forward_geocode(object.inputValue)
      .then((response) => {
        dispatch(processGeocodeResponse(response.data, object.index))
      })
      .catch((error) => {
        console.log(error) //eslint-disable-line
      })
      .finally(() => {})
  }
}

const processGeocodeResponse =
  (data, index, reverse, lngLat, permaLast) => (dispatch) => {
    const addresses = parseGeocodeResponse(data, lngLat)
    // if no address can be found
    if (addresses.length === 0) {
      dispatch(
        sendMessage({
          type: 'warning',
          icon: 'warning',
          description: 'Sorry, no addresses can be found.',
          title: 'No addresses',
        })
      )
      // for (const results of document.getElementsByClassName('results')) {
      //   results.classList.remove('visible')
      // }
    }
    dispatch(receiveGeocodeResults({ addresses, index }))

    if (reverse) {
      dispatch(
        updateTextInput({
          inputValue: addresses[0].title,
          index: index,
          addressindex: 0,
        })
      )
      if (permaLast === undefined) {
        dispatch(makeRequest())
        dispatch(updatePermalink())
      } else if (permaLast) {
        dispatch(makeRequest())
        dispatch(updatePermalink())
      }
    }
  }

export const receiveGeocodeResults = (object) => ({
  type: RECEIVE_GEOCODE_RESULTS_DISTRIBUTION,
  payload: object,
})

export const requestGeocodeResults = (object) => ({
  type: REQUEST_GEOCODE_RESULTS_DISTRIBUTION,
  payload: object,
})

export const updateTextInput = (object) => ({
  type: UPDATE_TEXTINPUT_DISTRIBUTION,
  payload: object,
})

export const doRemoveWaypoint = (index) => (dispatch, getState) => {
  if (index === undefined) {
    dispatch(clearWaypoints())
    Array(2)
      .fill()
      .map((_, i) => dispatch(doAddWaypoint()))
  } else {
    let waypoints = getState().distribution.waypoints
    if (waypoints.length > 2) {
      dispatch(clearWaypoints(index))
      dispatch(makeRequest())
    } else {
      dispatch(emptyWaypoint(index))
    }
    waypoints = getState().distribution.waypoints
    if (getActiveWaypoints(waypoints).length < 2) {
      dispatch(clearRoutes(VALHALLA_OSM_URL))
    }
  }
  dispatch(updatePermalink())
}

export const highlightManeuver = (fromTo) => (dispatch, getState) => {
  const highlightSegment = getState().distribution.highlightSegment
  // this is dehighlighting
  if (
    highlightSegment.startIndex === fromTo.startIndex &&
    highlightSegment.endIndex === fromTo.endIndex
  ) {
    fromTo.startIndex = -1
    fromTo.endIndex = -1
  }

  dispatch({
    type: HIGHLIGHT_MNV_DISTRIBUTION,
    payload: fromTo,
  })
}

export const zoomToManeuver = (zoomObj) => ({
  type: ZOOM_TO_MNV_DISTRIBUTION,
  payload: zoomObj,
})

export const clearWaypoints = (index) => ({
  type: CLEAR_WAYPOINTS_DISTRIBUTION,
  payload: { index: index },
})

export const emptyWaypoint = (index) => ({
  type: EMPTY_WAYPOINT_DISTRIBUTION,
  payload: { index: index },
})

export const updateInclineDeclineTotal = (object) => ({
  type: UPDATE_INCLINE_DECLINE_DISTRIBUTION,
  payload: object,
})

export const doAddWaypoint = (doInsert) => (dispatch, getState) => {
  const waypoints = getState().distribution.waypoints
  let maxIndex = Math.max.apply(
    Math,
    waypoints.map((wp) => {
      return wp.id
    })
  )
  maxIndex = isFinite(maxIndex) === false ? 0 : maxIndex + 1

  const emptyWp = {
    id: maxIndex.toString(),
    geocodeResults: [],
    isFetching: false,
    userInput: '',
  }
  if (doInsert) {
    dispatch(insertWaypoint(emptyWp))
  } else {
    dispatch(addWaypoint(emptyWp))
  }
}

const insertWaypoint = (waypoint) => ({
  type: INSERT_WAYPOINT_DISTRIBUTION,
  payload: waypoint,
})

export const addWaypoint = (waypoint) => ({
  type: ADD_WAYPOINT_DISTRIBUTION,
  payload: waypoint,
})

export const setWaypoints = (waypoints) => ({
  type: SET_WAYPOINT_DISTRIBUTION,
  payload: waypoints,
})

export const showProvider = (provider, show) => ({
  type: TOGGLE_PROVIDER_ISO_DISTRIBUTION,
  payload: {
    provider,
    show,
  },
})

export const updateDistributionProfile = (profile) => ({
  type: UPDATE_DISTRIBUTION_PROFILE,
  payload: profile,
})

export const resetDistributionSettings= (profile) => ({
  type: RESET_DISTRIBUTION_SETTINGS,
  payload: profile,
})

export const registerDistributionCostResponse = (data) => ({
  type: RECEIVE_DISTRIBUTION_COST_RESULTS,
  payload: {
    data
  }
})
import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import * as R from 'ramda'

import {
  Search, Form, Popup, Icon,
  // Label,
  Accordion,
  // Divider,
} from 'semantic-ui-react'
// import { Slider } from '@mui/material'

import { SettingsButton } from '../settings_button'
import { production_settings } from '../production-options'
import { isValidCoordinates } from 'utils/geom'
import {
  updateTextInput,
  updateIsoSettings,
  fetchGeocode,
  makeIsochronesRequest,
  clearIsos,
  updateCost,
} from 'actions/productionActions'

import { updatePermalink, zoomTo} from 'actions/commonActions'
import {updateProductionSettings} from 'actions/productionActions'
import CustomSlider from './slider'
import { debounce } from 'throttle-debounce'

class ProductionPlant extends Component {
  static propTypes = {
    production: PropTypes.object,
    dispatch: PropTypes.func,
    use_geocoding: PropTypes.bool,
    settings: PropTypes.object,
    profile: PropTypes.string
  }

  constructor(props) {
    super(props)
    this.fetchGeocodeResults = debounce(200, this.fetchGeocodeResults)
    this.handleIsoSliderUpdateSettings = debounce(
      10,
      this.handleIsoSliderUpdateSettings
    )
    this.state = { productionSettings: {} }
  }

  state = { activeIndex: 0, open: false }

  handleClick = (e, titleProps) => {
    const { index } = titleProps
    const { activeIndex } = this.state
    const newIndex = activeIndex === index ? -1 : index

    this.setState({ activeIndex: newIndex })
  }

  handleSearchChange = (event, { value }) => {
    const { dispatch } = this.props
    dispatch(updateTextInput({ userInput: value }))
  }

  handleRemoveProduction = (event, { value }) => {
    const { dispatch } = this.props
    dispatch(clearIsos())
  }

  handleUpdateSettings = ({ name, value }) => {
    const { dispatch } = this.props
    dispatch(
      updateProductionSettings({
        name,
        value,
      })
    )
    dispatch(updateCost())
  }

  fetchGeocodeResults(e) {
    const { dispatch, use_geocoding, production } = this.props
    const { userInput } = production

    this.setState({ open: true })

    if (userInput.length > 0 && e === 'Enter') {
      // make results visible
      if (use_geocoding) {
        dispatch(fetchGeocode(userInput))
      } else {
        const coords = userInput.split(/[\s,;]+/)
        // is this a coordinate?
        if (coords.length === 2) {
          const lat = coords[1]
          const lng = coords[0]
          if (isValidCoordinates(lat, lng)) {
            dispatch(
              fetchGeocode(userInput, [parseFloat(lng), parseFloat(lat)])
            )
          }
        }
      }
    }
  }

  // we really only want to call the valhalla backend if settings have changed
  // therefor using rambda for deep object comparison
  UNSAFE_componentWillUpdate(nextProps) {
    const { dispatch } = this.props
    const { maxRange, interval } = this.props.production
    const nextMaxRange = nextProps.production.maxRange
    const nextInterval = nextProps.production.interval
    if (
      !R.equals(maxRange, nextMaxRange) ||
      !R.equals(interval, nextInterval)
    ) {
      dispatch(makeIsochronesRequest())
    }
  }

  handleResultSelect = (e, { result }) => {
    this.setState({ open: false })

    const { dispatch } = this.props
    dispatch(
      updateTextInput({
        userInput: result.title,
        addressindex: result.addressindex,
      })
    )
    dispatch(zoomTo([[result.addresslnglat[1], result.addresslnglat[0]]]))
    dispatch(makeIsochronesRequest())
  }

  handleIntervalChange = (e, { value }) => {
    const { maxRange } = this.props.production

    value = isNaN(parseInt(value)) ? 0 : parseInt(value)
    if (value > maxRange) {
      value = maxRange
    }

    const intervalName = 'interval'

    this.handleIsoSliderUpdateSettings({
      intervalName,
      value,
    })
  }

  handleRangeChange = (e, { value }) => {
    value = isNaN(parseInt(value)) ? 0 : parseInt(value)
    if (value > 120) {
      value = 120
    }

    const maxRangeName = 'maxRange'
    const intervalName = 'interval'

    this.handleIsoSliderUpdateSettings({
      maxRangeName,
      intervalName,
      value,
    })
  }

  handleShowSettings = (settingsType, i) => {
    const settings = { ...this.state[settingsType] }
    settings[i] = !settings[i]
    this.setState({ [settingsType]: settings })
  }

  handleIsoSliderUpdateSettings = ({ value, maxRangeName, intervalName }) => {
    const { dispatch } = this.props
    // maxRangeName can be undefined if interval is being updated
    dispatch(
      updateIsoSettings({
        maxRangeName,
        intervalName,
        value: parseInt(value),
      })
    )

    dispatch(updatePermalink())
  }

  resultRenderer = ({ title, description }) => (
    <div className="flex-column">
      <div>
        <span className="title">{title}</span>
      </div>
      {/* {description && description.length > 0 && (
        // <div>
        //   <Icon disabled name="linkify" />
        //   <span className="description b">
        //     <a target="_blank" rel="noopener noreferrer" href={description}>
        //       OSM Link
        //     </a>
        //   </span>
        // </div>
        null
      )} */}
    </div>
  )

  render() {
    const { isFetching,
      geocodeResults,
      userInput,
      // maxRange,
      //  interval 
    } =
      this.props.production
    const { settings, dispatch, profile } = this.props
    const { activeIndex } = this.state

    return (
      <div>
        <div
          className="pa2 flex flex-row justify-between"
          style={{ alignItems: 'center' }}
        >
          <Search
            size="small"
            type="text"
            minCharacters={3}
            className={'pt2 pl3'}
            input={{ icon: 'search', iconPosition: 'left' }}
            onSearchChange={this.handleSearchChange}
            onResultSelect={this.handleResultSelect}
            resultRenderer={this.resultRenderer}
            showNoResults={false}
            open={this.state.open}
            onFocus={() => this.setState({ open: true })}
            onMouseDown={() => this.setState({ open: true })}
            loading={isFetching}
            results={geocodeResults}
            value={userInput}
            onKeyPress={(event: React.KeyboardEvent) => {
              this.fetchGeocodeResults(event.key)
            }}
            placeholder="Hit enter for search..."
          />
          <SettingsButton handleRemoveProduction={this.handleRemoveProduction} />
        </div>
        <div className="pa2">
          <Accordion>
            <Accordion.Title
              active={activeIndex === 0}
              index={0}
              onClick={this.handleClick}
            >
              <Icon name="dropdown" />
              <span className="f5">Settings</span>
            </Accordion.Title>
            <Accordion.Content active={activeIndex === 0}>
              <Form size={'small'}>
                <Fragment>
                  {
                    settings.production_type &&
                    production_settings[settings.production_type].numeric.map((option, key) => (
                      <div key={key}>
                        <div className="flex pointer">
                          <div
                            onClick={() =>
                              this.handleShowSettings('productionSettings', key)
                            }
                          >
                            <Icon
                              name={
                                this.state.productionSettings[key]
                                  ? 'caret down'
                                  : 'caret right'
                              }
                            />
                            <span className="b f6">{option.name}:</span>
                            {settings[option.param]} &emsp; {settings[option.unit]}
                          </div>
                          <div
                            style={{
                              marginLeft: 'auto',
                            }}
                          >
                            <Popup
                              content={option.description}
                              size={'tiny'}
                              trigger={
                                <Icon color="grey" name="help circle" />
                              }
                            />
                          </div>
                        </div>
                        <div >
                          {this.state.productionSettings[key] ? (
                            <CustomSlider
                              option={option}
                              dispatch={dispatch}
                              settings={settings}
                              profile={profile}
                              handleUpdateSettings={this.handleUpdateSettings}
                            />
                          ) : null}</div>
                      </div>
                    ))
                  }
                </Fragment>
              </Form>
            </Accordion.Content>
          </Accordion>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { production } = state
  const { use_geocoding } = state.common.settings
  const { profile } = state.common
  const { settings } = state.production

  return {
    production,
    use_geocoding,
    settings,
    profile
  }
}

export default connect(mapStateToProps)(ProductionPlant)
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { SemanticToastContainer, toast } from 'react-semantic-toasts'
import TransportControl from './Transport'
import ProductionControl from './Production'
import DistributionControl from './Distribution'
import TransportOutputControl from './Transport/OutputControl'
// import IsochronesOutputControl from './Isochrones/OutputControl'
import ProductionOutputControl from './Production/OutputControl'
import BenchmarkOutputControl from './Benchmark/OutputControl'
import DistributionOutputControl from './Distribution/OutputControl'
import {
  Segment,
  Tab,
  Button,
  Icon
} from 'semantic-ui-react'
import {
  updateTab,
  updateProfile,
  updatePermalink,
  zoomTo,
  resetSettings,
  toggleDirections,
} from 'actions/commonActions'
import { fetchReverseGeocodePerma } from 'actions/distributionActions'
// import {
//   fetchReverseGeocodeIso,
//   updateIsoSettings,
// } from 'actions/isochronesActions'

const pairwise = (arr, func) => {
  let cnt = 0
  for (let i = 0; i < arr.length - 1; i += 2) {
    func(arr[i], arr[i + 1], cnt)
    cnt += 1
  }
}
// class AddWhatsapp extends React.Component {
//   render() {
//     return <>Contact us on <a href='https://wa.me/491775427384 '>Whatsapp</a> or <a href="mailto:info@hyviate.com">Mail</a>.
//     </>;
//   }
// }

class IEA extends React.Component {
  render() {
    return <>All default parameters are based on <a href='https://www.iea.org/reports/the-future-of-hydrogen/data-and-assumptions'>
      IEA, The future of hydrogen.</a>
    </>;
  }
}

class MainControl extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    message: PropTypes.object,
    activeDataset: PropTypes.string,
    activeTab: PropTypes.number,
    showDirectionsPanel: PropTypes.bool,
  }

  componentDidMount = () => {
    const { dispatch } = this.props


    toast({
      type: 'success',
      icon: 'heart',
      title: `Welcome to HYVIATE's hydrogen maps!`,
      description: 'Hydrogen Routing Services - since 2022.',
      time: 10000,
    })

    // toast({
    //   type: 'info',
    //   // icon: 'info',
    //   title: 'Contact us',
    //   description: 'for dedicated multipoint studies and sensitivy analysis! ',
    //   time: 30000,
    // })

    // toast({
    //   type: 'info',
    //   // icon: 'info',
    //   title: 'Contact us',
    //   description: <AddWhatsapp />,
    //   time: 100000,
    // })

    // toast({
    //   type: 'warning',
    //   icon: 'info',
    //   title: 'Beta Version',
    //   description:
    //     'Current version is beta version. By using the tool you accept our terms and conditions.',
    //   delay: {
    //     show: 10000,
    //     hide: 10001
    //   }
    // })

    toast({
      type: 'info',
      icon: 'info',
      title: 'Calculations',
      description: <IEA />,
      delay: 10000,
      time: 20000,
    })

    const params = Object.fromEntries(new URL(document.location).searchParams)

    if ('profile' in params) {
      dispatch(updateProfile({ profile: params.profile }))
    }

    let activeTab
    if (
      window.location.pathname === '/production'
    ) {
      activeTab = 0
      dispatch(updateTab({ activeTab }))
    } else if (
      window.location.pathname === '/' ||
      window.location.pathname === '/transport') {
      activeTab = 1
      dispatch(updateTab({ activeTab }))
    } else if (window.location.pathname === '/distribution') {
      activeTab = 2
      dispatch(updateTab({ activeTab }))
    }

    if ('wps' in params && params.wps.length > 0) {
      const coordinates = params.wps.split(',').map(Number)
      const processedCoords = []
      pairwise(coordinates, (current, next, i) => {
        const latLng = { lat: next, lng: current }
        const payload = {
          latLng,
          fromPerma: true,
          permaLast: i === coordinates.length / 2 - 1,
          index: i,
        }
        processedCoords.push([latLng.lat, latLng.lng])
        if (activeTab === 0) {
          dispatch(fetchReverseGeocodePerma(payload))
        } 
        // else {
        //   dispatch(fetchReverseGeocodeIso(current, next))

        //   if ('range' in params && 'interval' in params) {
        //     const maxRangeName = 'maxRange'
        //     const intervalName = 'interval'
        //     const maxRangeValue = params.range
        //     const intervalValue = params.interval

        //     dispatch(
        //       updateIsoSettings({
        //         maxRangeName,
        //         intervalName,
        //         value: maxRangeValue,
        //       })
        //     )
        //     dispatch(
        //       updateIsoSettings({
        //         undefined,
        //         intervalName,
        //         value: intervalValue,
        //       })
        //     )
        //   }
        // }
      })
      dispatch(zoomTo(processedCoords))
      dispatch(resetSettings())
    }
  }

  componentDidUpdate = (prevProps) => {
    const { message } = this.props

    if (message.receivedAt > prevProps.message.receivedAt) {
      toast({
        type: message.type,
        icon: message.icon,
        title: message.topic,
        description: message.description,
        time: 5000,
      })
    }
  }

  handleTabChange = (event, data) => {
    const { dispatch } = this.props
    const activeTab = data.activeIndex

    dispatch(updateTab({ activeTab }))
    dispatch(updatePermalink())
  }

  handleDirectionsToggle = (event, data) => {
    const { dispatch } = this.props
    dispatch(toggleDirections())
  }

  render() {
    const { activeTab } = this.props
    const appPanes = [
      {
        menuItem: 'Production',
        render: () => (
          <Tab.Pane style={{ padding: '0 0 0 0' }} attached={true}>
            <ProductionControl />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Transport',
        render: () => (
          <Tab.Pane style={{ padding: '0 0 0 0' }} attached={true}>
            <TransportControl />
          </Tab.Pane>
        ),
      },
      {
        menuItem: 'Distribution',
        render: () => (
          <Tab.Pane style={{ padding: '0 0 0 0' }} attached={true}>
            <DistributionControl />
          </Tab.Pane>
        ),
      },
    ]

    const ServiceTabs = () => (
      <>
        <Button
          icon
          style={{ float: 'right', marginLeft: '5px' }}
          onClick={this.handleDirectionsToggle}
        >
          <Icon name="close" />
        </Button>
        <Tab
          activeIndex={activeTab}
          onTabChange={this.handleTabChange}
          menu={{ pointing: true }}
          panes={appPanes}
        />
      </>
    )

    return (
      <>
        <Button
          primary
          style={{
            zIndex: 998,
            top: '10px',
            left: '10px',
            position: 'absolute',
          }}
          onClick={this.handleDirectionsToggle}
        >
          {activeTab === 0 ? 'Production'
            : activeTab === 1 ? 'Transport' : 'Distribution'}
        </Button>
        <Drawer
          enableOverlay={false}
          open={this.props.showDirectionsPanel}
          direction="left"
          size="400px"
          style={{
            zIndex: 1000,
            overflow: 'auto',
          }}
        >
          <div>
            <Segment basic style={{ paddingBottom: 0 }}>
              <div>
                <ServiceTabs />
              </div>
            </Segment>
            <ProductionOutputControl />
            <TransportOutputControl />
            <DistributionOutputControl />
            <BenchmarkOutputControl />
          </div>
        </Drawer>
        <SemanticToastContainer position="bottom-center" />
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const { message, activeTab, showDirectionsPanel } = state.common
  return {
    message,
    activeTab,
    showDirectionsPanel,
  }
}

export default connect(mapStateToProps)(MainControl)

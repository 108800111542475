import React, { Fragment } from "react"
import {Form} from 'semantic-ui-react'
import PropTypes from 'prop-types'
import { debounce } from 'throttle-debounce'
const Checkbox = (props) => {
    const { settings, option, handleUpdateSettings } = props
  
    const handleChange = (e, { checked }) => {
      // const value = !!checked
      debounce(300,
        handleUpdateSettings({
          name: option.param,
          value: checked,
        })
      )
    }
  
    return (
      <Fragment>
        <Form.Checkbox
          width={10}
          label={option.name}
          checked={settings[option.param]}
          onChange={handleChange}
        />
      </Fragment>
    )
  }
  
  Checkbox.propTypes = {
    option: PropTypes.object,
    settings: PropTypes.object,
    dispatch: PropTypes.func,
    handleUpdateSettings: PropTypes.func,
  }

export default Checkbox
/**
* Calculates the Net Present Value of a given initial investment
* cost and an array of cash flow values with the specified discount rate.
*
* @param {number} rate - The discount rate percentage
* @param {number} initialCost - The initial investment
* @param {array} cashFlows - An array of future payment amounts
* @return {number} The calculated Net Present Value
*/
export const getNPV = (rate, initialCost, cashFlows) => {
  let npv = initialCost;
  for (let i = 0; i < cashFlows.length; i++) {
    npv += cashFlows[i] / Math.pow(rate / 100 + 1, i + 1);
  }
  return npv;
}

/**
 * 
 * @param {*} electrolysisEffiency 
 * @param {*} electricityCost 
 * @param {*} loadFactor 
 * @param {*} discountRate 
 * @param {*} electrolyzerCapex 
 * @returns Levelized cost of electrolyic hydrogen production
 */
export const updateElecProduction = (electrolysisEffiency, electricityCost, loadFactor, discountRate, electrolyzerCapex) => {
  const eleccost = 33000 / (electrolysisEffiency / 100) * electricityCost / 1000 / 1000
  const arr = [];
  arr.length = 20;
  arr.fill(electrolysisEffiency / 100 * loadFactor / 33322 * 1000);
  const capexcost = getNPV(discountRate, electrolyzerCapex, []) / getNPV(discountRate, 0, arr)
  return (eleccost + capexcost);
}

/**
 * 
 * @param {*} smrEfficiency 
 * @param {*} naturalGasCost 
 * @param {*} smrOpex 
 * @param {*} smrCapex 
 * @param {*} discountRate 
 * @returns Levelized cost of methanic hydrogen production
 */
export const updateSMRProduction = (smrEfficiency, naturalGasCost, smrOpex, smrCapex, discountRate) => {
  const gascost = 33000 / (smrEfficiency / 100) * naturalGasCost / 1000 / 1000
  const cashflow = [];
  cashflow.length = 30;
  const volume = [];
  volume.length = 30;
  volume.fill(24 * 365 / 33322 * 1000);
  cashflow.fill(gascost * volume[0] + smrOpex * smrCapex / 100);
  const levelizedcost = getNPV(discountRate, Number(smrCapex), cashflow)
    / getNPV(discountRate, 0, volume)
  return levelizedcost;
}
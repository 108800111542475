import React from 'react'

export const SettingsFooter = () => {
  return (
    <div className="ml2">
      <span className="custom-label">
        Calculations by{' '}
        <a
          href="https://hyviate.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          HYVIATE
        </a>{' '}
        • Default values from <a href="https://www.iea.org/reports/the-future-of-hydrogen/data-and-assumptions">IEA</a> / <a href='https://joint-research-centre.ec.europa.eu/system/files/2021-06/jrc124206_assessment_of_hydrogen_delivery_options.pdf'>JRC</a> 
        {/* visualized with{' '} */}
        {/* <a
          href="https://github.com/gis-ops/valhalla-app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          Valhalla App
        </a> */}
      </span>
    </div>
  )
}

import { combineReducers } from 'redux'
import { common } from './common'
import { production } from './production'
import { transport} from './transport'
import { distribution } from './distribution'
const rootReducer = combineReducers({
  common,
  production,
  transport,
  distribution
})

export default rootReducer

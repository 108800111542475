export const settingsInit = {
    electricityCost: 30,
    electrolysisEffiency: 70,
    discountRate: 10,
    loadFactor: 4000,
    electrolyzerCapex: 700,
    smrEfficiency: 76,
    naturalGasCost: 50,
    smrCapex: 700,
    smrOpex: 4.7,
    useBenchmark: false,
    benchmark_type: 'naturalGas',
    benchmark_value: 150,
    benchmark_value_H2: 6
  }

  export const productionType = {
    name: 'Production Type',
    description: `The type of h2 production. The default type is Electrolytic.`,
    param: 'production_type',
    enums: [
      {
        key: 'Electrolytic',
        text: 'Electrolytic H2',
        value: 'Electrolytic',
      },
      {
        key: 'SMR',
        text: 'Steam Methane Reforming H2',
        value: 'SMR',
      },
    ],
  }
    
  const electrolysisEffiency = {
    name: 'Electrolysis Efficiency',
    param: 'electrolysisEffiency',
    description:
      'This value indicates the efficiency of electrolysis process.',
    unit: '%',
    settings: {
      min: 0,
      max: 100,
      step: 1,
    },
  }
  
  const electricityCost = {
    name: 'Cost of Electricity',
    param: 'electricityCost',
    description:
      'This value indicates the cost of electricity to electrolyse.',
    unit: 'EUR/MWh',
    settings: {
      min: 0,
      max: 100,
      step: 1,
    },
  }
  
  const discountRate = {
    name: 'Discount rate ',
    param: 'discountRate',
    description:
      'This value indicates the discount rate used in the levelized cost of energy calculations.',
    unit: '%',
    settings: {
      min: 0,
      max: 20,
      step: 1,
    },
  }
  
  const electrolyzerCapex = {
    name: 'Electrolyzer Capex ',
    param: 'electrolyzerCapex',
    description:
      'This value indicates the discount rate used in the levelized cost of energy calculations.',
    unit: 'EUR/kW',
    settings: {
      min: 0,
      max: 1000,
      step: 1,
    },
  }
  
  const loadFactor = {
    name: 'Load Factor ',
    param: 'loadFactor',
    description:
      'This value indicates the full load hours that renewable energy is available during the year.',
    unit: 'hours',
    settings: {
      min: 0,
      max: 8760,
      step: 1,
    },
  }
  
  const smrEfficiency = {
    name: 'SMR Efficiency ',
    param: 'smrEfficiency',
    description:
      'This value indicates the efficiency of output on hydrogen versus natural gas input.',
    unit: '%',
    settings: {
      min: 0,
      max: 100,
      step: 1,
    },
  }
  
  const smrCapex = {
    name: 'SMR Capex',
    param: 'smrCapex',
    description:
      'This value indicates the specific capital cost per output of hydrogen.',
    unit: 'EUR/kWH2',
    settings: {
      min: 0,
      max: 1000,
      step: 1,
    },
  }
  
  const naturalGasCost = {
    name: 'Natural Gas Cost ',
    param: 'naturalGasCost',
    description:
      'This value indicates the energy cost of natural gas input.',
    unit: 'EUR/MWh',
    settings: {
      min: 0,
      max: 300,
      step: 1,
    },
  }
  
  const smrOpex = {
    name: 'SMR Opex ',
    param: 'smrOpex',
    description:
      'This value indicates the yearly opering cost versus the capital cost of the smr plant.',
    unit: '%',
    settings: {
      min: 0,
      max: 10,
      step: .1,
    },
  }
  
  export const production_settings = {
    Electrolytic: {
      numeric: [
        electrolysisEffiency,
        electricityCost,
        electrolyzerCapex,
        loadFactor,
        discountRate,
      ]
    },
    SMR: {
      numeric: [
        smrEfficiency,
        naturalGasCost,
        smrCapex,
        smrOpex,
        discountRate,
      ]
    },
  }
import {
  RECEIVE_GEOCODE_RESULTS_PRODUCTION,
  // REQUEST_GEOCODE_RESULTS_ISO,
  // RECEIVE_PRODUCTION_RESULTS,
  // UPDATE_SETTINGS_PRODUCTION,
  UPDATE_TEXTINPUT_PRODUCTION,
  // TOGGLE_PROVIDER_PRODUCTION,
  CLEAR_ISOS,
  REQUEST_GEOCODE_RESULTS_PRODUCTION,
  UPDATE_PRODUCTION_COST,
  // UPDATE_SETTINGS,
  UPDATE_PRODUCTION_SETTINGS
} from 'actions/types'

import { settingsInit } from 'Controls/Production/production-options'

const initialState = {
  successful: false,
  userInput: '',
  isFetching: false,
  geocodeResults: [],
  selectedAddress: '',
  cost: null,
  settings: { ...settingsInit }
}

export const production = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case CLEAR_ISOS:
      return {
        ...state,
        successful: false,
        userInput: '',
        geocodeResults: [],
        selectedAddress: '',
        results: initialState.results,
      }

    // case TOGGLE_PROVIDER_PRODUCTION:
    //   return {
    //     ...state,
    //     results: {
    //       ...state.results,
    //       [action.payload.provider]: {
    //         ...state.results[action.payload.provider],
    //         show: payload.show,
    //       },
    //     },
    //   }
    // case RECEIVE_PRODUCTION_RESULTS:
    //   return {
    //     ...state,
    //     results: {
    //       ...state.results,
    //       [action.payload.provider]: {
    //         ...state.results[action.payload.provider],
    //         data: action.payload.data,
    //       },
    //     },
    //     successful: true,
    //   }

    // case UPDATE_SETTINGS_PRODUCTION:
    //   return {
    //     ...state,
    //     [payload.maxRangeName]: payload.value,
    //     [payload.intervalName]: payload.value,
    //   }

    case UPDATE_TEXTINPUT_PRODUCTION:
      return {
        ...state,
        userInput: payload.userInput,
        selectedAddress: state.geocodeResults[action.payload.addressindex],
        geocodeResults: state.geocodeResults.map((result, i) =>
          i === action.payload.addressindex
            ? { ...result, selected: true }
            : { ...result, selected: false }
        ),
      }

    case RECEIVE_GEOCODE_RESULTS_PRODUCTION:
      return {
        ...state,
        geocodeResults: payload,
        isFetching: false,
        successful: true,
      }

    case REQUEST_GEOCODE_RESULTS_PRODUCTION:
      return {
        ...state,
        isFetching: true,
      }

    case UPDATE_PRODUCTION_COST:
      return {
        ...state,
        cost: payload
      }

    case UPDATE_PRODUCTION_SETTINGS: {
      const { name, value } = action.payload
      return {
        ...state,
        settings: {
          ...state.settings,
          [name]: value,
        },
      }
    }

    default:
      return state
  }
}
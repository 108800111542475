const useBenchmark = {
    name: 'Benchmark',
    description:
      'Decides whether you want to use benchmark to compare costs.',
    param: 'use_benchmark',
  }
  

export const benchmarkType = {
    name: 'Benchmark Type',
    description: `The type of benchmark. The default type is natural gas.`,
    param: 'benchmark_type',
    enums: [
      {
        key: 'naturalGas',
        text: 'Natural Gas [MWh]',
        value: 'naturalGas',
      },
      {
        key: 'diesel',
        text: 'Diesel',
        value: 'diesel',
      },
      {
        key: 'hydrogen',
        text: 'Local Hydrogen',
        value: 'hydrogen',
      },
    ],
    sliderSettings: {
      'naturalGas': {
        key: 'naturalGas',
        unit: 'EUR/MWh',
        text: 'Natural Gas [MWh]',
        param: 'benchmark_value',
        conversion_factor: 0.0300175,
        settings: {
          min: 0,
          max: 250,
          step: 1,
        },
      },
      'diesel':{
        key: 'diesel',
        unit: 'EUR/L',
        text: 'Diesel',
        param: 'benchmark_value',
        conversion_factor: 3.107002294,
        settings: {
          min: 0,
          max: 3,
          step: .1,
        },
      },
      'hydrogen':{
        key: 'diesel',
        unit: `EUR/kgH2`,
        text: 'Diesel',
        param: 'benchmark_value',
        conversion_factor: 1,
        settings: {
          min: 0,
          max: 20,
          step: .1,
        },
    }}}

export const settings_benchmark = {
    all: {
      boolean: [useBenchmark],
    },
  }
import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Segment, Divider } from 'semantic-ui-react'
import { Label, Popup, } from 'semantic-ui-react'
// import Summary from './Summary'
// import { makeIsochronesRequest } from 'actions/isochronesActions'
// import ContoursInformation from './ContoursInformation'
// import { VALHALLA_OSM_URL } from 'utils/valhalla'
// import { updateProduction} from 'utils/production'
// import { updateCost } from 'actions/productionActions'
// import { production } from 'reducers/production'


class OutputControl extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    // profile: PropTypes.string,
    // activeTab: PropTypes.number,
    successful: PropTypes.bool,
    // results: PropTypes.object,
    settings: PropTypes.object,
    cost: PropTypes.number,
    production: PropTypes.object,
    transport: PropTypes.object,
    distribution: PropTypes.object,
  }

  // necessary to calculate new routes the tab was changed from isochrone tab
  // need to do this every time, because "profile" is global (otherwise we would
  // calculate new when the profile was changed while being on the iso tab)
  // shouldComponentUpdate(nextProps, nextState, nextContext) {
  //   if (nextProps.activeTab === 1 && this.props.activeTab === 0) {
  //     nextProps.dispatch(makeIsochronesRequest())
  //   }
  //   if (nextProps.activeTab === 0) {
  //     return false
  //   }
  //   return true
  // }


  render() {
    const { successful, cost, production,transport,distribution } = this.props
    const { settings } = this.props
    // const { dispatch } = this.props

    return (
      <> {
        settings.use_benchmark &&
        <Segment
          style={{
            margin: '0 1rem',
            display: (production.settings.production_type && successful && transport.cost && distribution.cost) ? 'block' : 'none',
          }}
        >
          <div className={'flex-column'}>
            <div className={'flex justify-between pointer'}>
              <span className="b">Total vs. Benchmark {settings.benchmark_type}</span><Popup
                content={'Units'}
                size={'tiny'}
                trigger={
                  <Label allign='right' basic size={'small'} style={{ cursor: 'default' }}>
                    {<>EUR/kgH<sub>2</sub></>}
                  </Label>
                }
              />
              {/* <Summary/> */}
            </div>
            <Divider />
            <div className='text-right'>
              {(settings.benchmark_value_kg - cost - transport.cost-distribution.cost) < 0 ?
                <h1 className='benchmark-red'> &Delta;
                  {(settings.benchmark_value_kg - cost - transport.cost-distribution.cost) < 0 ? "+" : null}
                  {settings.benchmark_value_kg ?
                    (-settings.benchmark_value_kg + cost + transport.cost+distribution.cost).toFixed(2)
                    : null}
                  <Label allign='right' basic size={'small'} style={{ cursor: 'default' }}>
                    {<>EUR/kgH<sub>2</sub></>}
                  </Label>
                </h1> :
                <h1 className='benchmark-green'> &Delta;
                  {settings.benchmark_value_kg  && transport.cost ?
                    (-settings.benchmark_value_kg + cost + transport.cost ).toFixed(2)
                    : null}
                  <Label allign='right' basic size={'small'} style={{ cursor: 'default' }}>
                    {<>EUR/kgH<sub>2</sub></>}
                  </Label>
                </h1>}


            </div>
          </div>
        </Segment>}
      </>

    )
  }
}

const mapStateToProps = (state) => {
  const { settings, } = state.common
  const { successful, cost, } = state.production
  const { production, transport,distribution } = state
  // const { successful, results } = state.isochrones
  return {
    // profile,
    // activeTab,
    successful,
    // results,
    settings,
    cost,
    production,
    transport,
    distribution
  }
}

export default connect(mapStateToProps)(OutputControl)

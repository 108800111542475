export const UPDATE_SETTINGS = 'UPDATE_SETTINGS'
export const UPDATE_PROFILE = 'UPDATE_PROFILE'
export const ADD_WAYPOINT = 'ADD_WAYPOINT'
export const SET_WAYPOINT = 'SET_WAYPOINT'
export const CLEAR_WAYPOINTS = 'CLEAR_WAYPOINTS'
export const UPDATE_TEXTINPUT = 'UPDATE_TEXTINPUT'
export const REQUEST_GEOCODE_RESULTS = 'REQUEST_GEOCODE_RESULTS'
export const RECEIVE_GEOCODE_RESULTS = 'RECEIVE_GEOCODE_RESULTS'
export const FETCH_GEOCODE = 'FETCH_GEOCODE'
export const MESSAGE_HANDLER = 'RESULT_HANDLER'
export const UPDATE_TEXTINPUT_ISO = 'UPDATE_TEXTINPUT_ISO'
export const REQUEST_GEOCODE_RESULTS_ISO = 'REQUEST_GEOCODE_RESULTS_ISO'
export const RECEIVE_GEOCODE_RESULTS_ISO = 'RECEIVE_GEOCODE_RESULTS_ISO'
export const UPDATE_SETTINGS_ISO = 'UPDATE_SETTINGS_ISO'
export const EMPTY_WAYPOINT = 'EMPTY_WAYPOINT'
export const CONSTRUCT_REQUEST = 'CONSTRUCT_REQUEST'
export const UPDATE_TAB = 'UPDATE_TAB'
export const LOADING = 'LOADING'
export const INSERT_WAYPOINT = 'INSERT_WAYPOINT'
export const RECEIVE_ROUTE_RESULTS = 'RECEIVE_ROUTE_RESULTS'
export const CLEAR_ROUTES = 'CLEAR_ROUTES'
export const TOGGLE_PROVIDER_ISO = 'TOGGLE_PROVIDER'
export const RECEIVE_ISOCHRONE_RESULTS = 'RECEIVE_ISOCHRONE_RESULTS'
export const CLEAR_ISOS = 'CLEAR_ISOS'
export const SHOW_SETTINGS = 'SHOW_SETTINGS'
export const TOGGLE_DIRECTIONS = 'TOGGLE_DIRECTIONS'
export const HIGHLIGHT_MNV = 'HIGHLIGHT_MNV'
export const ZOOM_TO_MNV = 'ZOOM_TO_MNV'
export const ZOOM_TO = 'ZOOM_TO'
export const RESET_SETTINGS = 'RESET_SETTINGS'
export const UPDATE_INCLINE_DECLINE = 'UPDATE_INCLINE_DECLINE'
export const SET_PRODUCTION_LOCATION = 'SET_PRODUCTION_LOCATION'
export const SET_BENCHMARK_LOCATION = 'SET_BENCHMARK_LOCATION'
export const REQUEST_GEOCODE_RESULTS_PRODUCTION = 'REQUEST_GEOCODE_RESULTS_PRODUCTION'
export const UPDATE_SETTINGS_PRODUCTION = 'UPDATE_SETTINGS_PRODUCTION'
export const UPDATE_TEXTINPUT_PRODUCTION = 'UPDATE_TEXTINPUT_PRODUCTION'
export const TOGGLE_PROVIDER_PRODUCTION = 'TOGGLE_PROVIDER_PRODUCTION'
export const RECEIVE_GEOCODE_RESULTS_PRODUCTION = 'RECEIVE_GEOCODE_RESULTS_PRODUCTION'
export const RECEIVE_PRODUCTION_RESULTS = 'RECEIVE_PRODUCTION_RESULTS'
export const UPDATE_PRODUCTION_COST = 'UPDATE_PRODUCTION_COST'
export const UPDATE_PRODUCTION_SETTINGS = 'UPDATE_PRODUCTION_SETTINGS'
export const UPDATE_TRANSPORT_SETTINGS = 'UPDATE_TRANSPORT_SETTINGS'
export const RECEIVE_TRANSPORT_RESULTS = 'RECEIVE_TRANSPORT_RESULTS'
export const RECEIVE_COST_RESULTS = 'RECEIVE_COST_RESULTS'
export const UPDATE_TRANSPORT_PROFILE = 'UPDATE_TRANSPORT_PROFILE'
export const RESET_TRANSPORT_SETTINGS = 'RESET_TRANSPORT_SETTINGS'
export const UPDATE_TEXTINPUT_DISTRIBUTION = 'UPDATE_TEXTINPUT_DISTRIBUTION'
export const CLEAR_WAYPOINTS_DISTRIBUTION = 'CLEAR_WAYPOINTS_DISTRIBUTION'
export const ADD_WAYPOINT_DISTRIBUTION ='ADD_WAYPOINT_DISTRIBUTION'
export const RECEIVE_GEOCODE_RESULTS_DISTRIBUTION = 'RECEIVE_GEOCODE_RESULTS_DISTRIBUTION'
export const REQUEST_GEOCODE_RESULTS_DISTRIBUTION = 'REQUEST_GEOCODE_RESULTS_DISTRIBUTION'
export const SET_WAYPOINT_DISTRIBUTION = 'SET_WAYPOINT_DISTRIBUTION'
// UPDATE_TEXTINPUT,
export const EMPTY_WAYPOINT_DISTRIBUTION = 'EMPTY_WAYPOINT_DISTRIBUTION'
export const INSERT_WAYPOINT_DISTRIBUTION = 'INSERT_WAYPOINT_DISTRIBUTION'
export const RECEIVE_ROUTE_RESULTS_DISTRIBUTION = 'RECEIVE_ROUTE_RESULTS_DISTRIBUTION'
export const CLEAR_ROUTES_DISTRIBUTION = 'CLEAR_ROUTES_DISTRIBUTION'
export const TOGGLE_PROVIDER_ISO_DISTRIBUTION = 'TOGGLE_PROVIDER_ISO_DISTRIBUTION'
export const HIGHLIGHT_MNV_DISTRIBUTION = 'HIGHLIGHT_MNV_DISTRIBUTION'
export const ZOOM_TO_MNV_DISTRIBUTION = 'ZOOM_TO_MNV_DISTRIBUTION'
export const UPDATE_INCLINE_DECLINE_DISTRIBUTION = 'UPDATE_INCLINE_DECLINE_DISTRIBUTION'
export const UPDATE_HYVIATE_PROFILE="UPDATE_HYVIATE_PROFILE"
export const UPDATE_SPECIFIC_CAPEX="UPDATE_SPECIFIC_CAPEX"
export const RESET_DISTRIBUTION_SETTINGS = "RESET_DISTRIBUTION_SETTINGS"
export const UPDATE_DISTRIBUTION_PROFILE="UPDATE_DISTRIBUTION_PROFILE"
export const RECEIVE_DISTRIBUTION_COST_RESULTS="RECEIVE_DISTRIBUTION_COST_RESULTS"
export const RECEIVE_COST_DETAILS_RESULTS="RECEIVE_COST_DETAILS_RESULTS"
import {
  ADD_WAYPOINT,
  INSERT_WAYPOINT,
  SET_WAYPOINT,
  CLEAR_WAYPOINTS,
  EMPTY_WAYPOINT,
  UPDATE_TEXTINPUT,
  REQUEST_GEOCODE_RESULTS,
  RECEIVE_GEOCODE_RESULTS,
  // RECEIVE_ROUTE_RESULTS,
  CLEAR_ROUTES,
  TOGGLE_PROVIDER_ISO,
  HIGHLIGHT_MNV,
  ZOOM_TO_MNV,
  UPDATE_INCLINE_DECLINE,
  UPDATE_TRANSPORT_SETTINGS,
  RECEIVE_TRANSPORT_RESULTS,
  RECEIVE_COST_RESULTS,
  UPDATE_TRANSPORT_PROFILE,
  RESET_SETTINGS,
  UPDATE_HYVIATE_PROFILE,
  UPDATE_SPECIFIC_CAPEX,
} from 'actions/types'

import { ROUTING_URL } from '../utils/transport'
import { transportSettingsInit,settingsInitHyviateOverrideLongTerm,settingsInitHyviateOverrideShortTerm,settingsInitHyviateOverrideMediumTerm,
   settingsPipelineOverride,
    settingsAmmoniaOverride,settingsLh2Override,settingsLohcOverride } from 'Controls/Transport/transport-options'

const initialState = {
  profile: 'ammonia_ship',
  cost: 0,
  successful: false,
  highlightSegment: {
    startIndex: -1,
    endIndex: -1,
  },
  waypoints: [],
  zoomObj: {
    index: -1,
    timeNow: -1,
  },
  selectedAddresses: '',
  results: {
    [ROUTING_URL]: {
      data: {},
      show: true,
    },
  },
  settings: { ...settingsAmmoniaOverride }
}

export const transport = (state = initialState, action) => {
  // console.log(action) //eslint-disable-line
  switch (action.type) {
    case UPDATE_INCLINE_DECLINE:
      return {
        ...state,
        inclineDeclineTotal: { ...action.payload },
      }

    case TOGGLE_PROVIDER_ISO:
      return {
        ...state,
        results: {
          ...state.results,
          [action.payload.provider]: {
            ...state.results[action.payload.provider],
            show: action.payload.show,
          },
        },
      }

    case CLEAR_ROUTES:
      return {
        ...state,
        successful: false,
        inclineDeclineTotal: undefined,
        results: {
          ...state.results,
          [action.payload]: {
            ...state.results[action.payload],
            data: {},
          },
        },
      }

    case RECEIVE_TRANSPORT_RESULTS:
      return {
        ...state,
        inclineDeclineTotal: undefined,
        results: {
          ...state.results,
          [action.payload.provider]: {
            ...state.results[action.payload.provider],
            data: action.payload.data,
          },
        },
        successful: true,
      }

    case RECEIVE_COST_RESULTS:
      return {
        ...state,
        cost: Number(action.payload.data.cost.toFixed(2)),
        cost_details:action.payload.data.summary
      }

    case UPDATE_SPECIFIC_CAPEX:
      return {
        ...state,
        settings:{
          ...state.settings,
          pipeline_specific_capex: Number(action.payload.data.toFixed(2))
        }
      }

    case RECEIVE_GEOCODE_RESULTS:
      return {
        ...state,
        waypoints: state.waypoints.map((waypoint, i) =>
          i === action.payload.index
            ? {
              ...waypoint,
              isFetching: false,
              geocodeResults: action.payload.addresses,
            }
            : waypoint
        ),
      }

    case REQUEST_GEOCODE_RESULTS:
      return {
        ...state,
        waypoints: state.waypoints.map((waypoint, i) =>
          i === action.payload.index
            ? { ...waypoint, isFetching: true }
            : waypoint
        ),
      }

    case UPDATE_TEXTINPUT:
      // Catch array of selectedAddress from all waypoints
      // eslint-disable-next-line no-case-declarations
      const selectedAddresses = []
      state.waypoints.forEach((waypoint) => {
        waypoint.geocodeResults.forEach((result, i) => {
          selectedAddresses.push(
            i === action.payload.addressindex ? waypoint : null
          )
        })
      })
      return {
        ...state,
        selectedAddresses: selectedAddresses,
        waypoints: state.waypoints.map((waypoint, i) =>
          i === action.payload.index
            ? {
              ...waypoint,
              userInput: action.payload.inputValue,
              geocodeResults: waypoint.geocodeResults.map((result, i) =>
                i === action.payload.addressindex
                  ? { ...result, selected: true }
                  : { ...result, selected: false }
              ),
            }
            : waypoint
        ),
      }

    case CLEAR_WAYPOINTS: {
      return {
        ...state,
        waypoints:
          action.payload.index >= 0
            ? state.waypoints.filter((v, i) => i !== action.payload.index)
            : [],
      }
    }

    case EMPTY_WAYPOINT: {
      return {
        ...state,
        waypoints: state.waypoints.map((waypoint, i) =>
          i === action.payload.index
            ? {
              ...waypoint,
              userInput: '',
              geocodeResults: [],
            }
            : waypoint
        ),
      }
    }

    case SET_WAYPOINT: {
      return {
        ...state,
        waypoints: action.payload,
      }
    }

    case ADD_WAYPOINT: {
      return {
        ...state,
        waypoints: [...state.waypoints, action.payload],
      }
    }

    case INSERT_WAYPOINT: {
      const waypoints = state.waypoints
      waypoints.splice(waypoints.length - 1, 0, action.payload)

      return {
        ...state,
        waypoints: [...waypoints],
      }
    }

    case HIGHLIGHT_MNV: {
      return {
        ...state,
        highlightSegment: action.payload,
      }
    }

    case ZOOM_TO_MNV: {
      return {
        ...state,
        zoomObj: action.payload,
      }
    }

    default: {
      return state
    }

    case UPDATE_TRANSPORT_SETTINGS: {
      const { name, value } = action.payload
      return {
        ...state,
        settings: {
          ...state.settings,
          [name]: value,
        },
      }
    }

    case UPDATE_HYVIATE_PROFILE : {
      const {value} = action.payload
      switch (value) {
        case 'shortterm':
          return {
            ...state,
            settings: {
              ...settingsInitHyviateOverrideShortTerm
            }
          }
          case 'midterm':
            return {
              ...state,
              settings: {
                ...settingsInitHyviateOverrideMediumTerm
              }
            }
            case 'longterm':
              return {
                ...state,
                settings: {
                  ...settingsInitHyviateOverrideLongTerm
                }
              }
            }
            break;
          }

    case UPDATE_TRANSPORT_PROFILE: {
      const { profile } = action.payload
      switch (profile) {
        case 'hyviate':
          return {
            ...state,
            profile,
            settings: { ...settingsInitHyviateOverrideLongTerm }
          }
        case 'pipeline':
          return {
            ...state,
            profile,
            settings: { ...settingsPipelineOverride }
          }
        case 'ammonia_ship':
          return {
            ...state,
            profile,
            settings: { ...settingsAmmoniaOverride }
          }
        case 'lohc_ship':
          return {
            ...state,
            profile,
            settings: { ...settingsLohcOverride }
          }
        case 'lh2_ship':
          return {
            ...state,
            profile,
            settings: { ...settingsLh2Override }
          }
      }
      break;
    }

    case RESET_SETTINGS: {
      return {
        ...state,
        profile: action.payload,
        settings: {
          ...(action.payload.profile === 'pipeline'
            ? settingsPipelineOverride
            : transportSettingsInit),
          // : transportSettingsInit),
        },
      }
    }
  }
}

import React from 'react'
import PropTypes from 'prop-types'
import { Button, Popup } from 'semantic-ui-react'

import { ReactComponent as TruckSvg } from 'images/truck.svg'
import { ReactComponent as Hyviate} from 'images/hyviate.svg'
import { ReactComponent as Pipeline} from 'images/pipeline.svg'
import { ReactComponent as AmmoniaShip} from 'images/ammonia_ship.svg'
import { ReactComponent as LohcShip} from 'images/lohc_ship.svg'
import { ReactComponent as Lh2Ship} from 'images/lh2_ship.svg'
import { ReactComponent as AmmoniaTruck } from 'images/ammonia_truck.svg'
import { ReactComponent as Lh2Truck } from 'images/lh2_truck.svg'
import { ReactComponent as Gh2Truck } from 'images/gh2_truck.svg'
import { ReactComponent as LohcTruck } from 'images/lohc_truck.svg'

const iconMap = {
  truck: <TruckSvg />,
  hyviate: <Hyviate />,
  pipeline: <Pipeline />,
  ammonia_ship: <AmmoniaShip/>,
  lohc_ship: <LohcShip/>,
  lh2_ship: <Lh2Ship/>,
  ammonia_truck: <AmmoniaTruck/>,
  lh2_truck: <Lh2Truck/>,
  gh2_truck: <Gh2Truck/>,
  lohc_truck: <LohcTruck/>,
}

export const ProfilePicker = ({
  group,
  loading,
  popupContent,
  profiles,
  activeProfile,
  handleUpdateProfile,
}) => (
  <Button.Group basic size="small" style={{ height: '40px' }}>
    {profiles.map((profile, i) => (
      <Popup
        key={i}
        content={popupContent[i]}
        size={'small'}
        trigger={
          <Button
            active={profile === activeProfile}
            loading={profile === activeProfile ? loading : false}
            content={iconMap[profile]}
            name={'profile'}
            selected_profile={profile}
            group={group}
            style={{ padding: '.5em' }}
            onClick={handleUpdateProfile}
          />
        }
      />
    ))}
  </Button.Group>
)
ProfilePicker.propTypes = {
  profiles: PropTypes.array,
  loading: PropTypes.bool,
  activeProfile: PropTypes.string,
  handleUpdateProfile: PropTypes.func,
  group: PropTypes.string,
  popupContent: PropTypes.array,
}

import React, { Fragment } from 'react'
import * as R from 'ramda'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { debounce } from 'throttle-debounce'
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { toast } from 'react-semantic-toasts'
import {
  Divider,
  Form,
  Grid,
  Header,
  Icon,
  Popup,
  Segment,
  Accordion,
  Dropdown,
  Button,
} from 'semantic-ui-react'
// import { CopyToClipboard } from 'react-copy-to-clipboard'
// import { 
//   profile_settings,
//   //  settings_general 
//   } from './settings-options'
import {
  transport_settings,
  h2carrier_settings
} from './transport-options'
// import {
//   filterProfileSettings,
// } from 'actions/transportActions'
import { resetSettings, doShowSettings } from 'actions/commonActions'
import { updateTransportSettings,updateHyviate,
  makeSpecificCapexRequest 
} from 'actions/transportActions'
import CustomSlider from 'components/CustomSlider'
import { makeCostRequest } from 'actions/transportActions'
// import { makeIsochronesRequest } from 'actions/isochronesActions'
import Benchmark from 'Controls/Benchmark'
import Checkbox from 'components/Checkbox'
import HeaderSubHeader from 'semantic-ui-react/dist/commonjs/elements/Header/HeaderSubheader'
class SettingsPanel extends React.Component {
  static propTypes = {
    id: PropTypes.number,
    index: PropTypes.number,
    activeTab: PropTypes.number,
    message: PropTypes.object,
    profile: PropTypes.string,
    settings: PropTypes.object,
    showSettings: PropTypes.bool,
    dispatch: PropTypes.func.isRequired,
    transport: PropTypes.object
  }

  constructor(props) {
    super(props)
    this.handleUpdateSettings = debounce(100, this.handleUpdateSettings)
    this.state = {
      activeIndexProfile: 0,
      activeIndexGeneral: 0,
      generalSettings: {},
      transportSettings: {},
      conversionSettings:{},
      reconversionSettings:{},
      extraSettings: {},
      copied: false,
      diameter_warning_shown:false,
    }
  }

  handleUpdateSettings = ({ name, value }) => {
    const { dispatch } = this.props
    dispatch(
      updateTransportSettings({
        name,
        value,
      })
    )
  }

  // the react slider will update the settings twice
  // we however only want this component to update if the
  // settings really change, therefor deep check with ramda
  shouldComponentUpdate(nextProps, nextState) {
    const { settings, profile, showSettings,activeTab } = this.props

    if (!R.equals(settings, nextProps.settings)) {
      return true
    }
    if (!R.equals(activeTab, nextProps.activeTab)) {
      return true
    }
    if (!R.equals(profile, nextProps.profile)) {
      return true
    }
    if (!R.equals(showSettings, nextProps.showSettings)) {
      return true
    }
    if (!R.equals(this.state, nextState)) {
      return true
    }
    return false
  }
  // we really only want to call the valhalla backend if settings have changed
  // therefor using rambda for deep object comparison
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { dispatch, settings, activeTab, profile } = this.props

    if (!R.equals(profile, nextProps.profile)) {
      const { transportSettings } = this.state
      Object.keys(transportSettings).forEach((v) => (transportSettings[v] = true))

      const { generalSettings } = this.state
      Object.keys(generalSettings).forEach((v) => (generalSettings[v] = true))

      const { extraSettings } = this.state
      Object.keys(extraSettings).forEach((v) => (extraSettings[v] = true))

      const { conversionSettings } = this.state
      Object.keys(conversionSettings).forEach((v) => (conversionSettings[v] = true))

      this.setState({ transportSettings,generalSettings,extraSettings,conversionSettings })
    }


    if (!R.equals(settings, nextProps.settings)) {
      if (activeTab === 1) {
        dispatch(makeCostRequest())
      } 
      // else {
      //   dispatch(makeIsochronesRequest())
      // }
    }



    // Automatically update pipeline capex based on diameter and indicate to user
    if (!R.equals(settings.pipeline_diameter, nextProps.settings.pipeline_diameter)) {
      let { transportSettings } = this.state
      transportSettings = { ...transportSettings, 1:true}
      this.setState({ transportSettings })
      if (!this.state.diameter_warning_shown) {
        toast({
        type: 'warning',
        icon: 'warning',
        title: `Warning`,
        description: 'Significant diameter change will alter pipeline capex per unit length.',
        time: 10000,
      })
      this.setState({diameter_warning_shown:true})
      }
      
      if (activeTab === 1 && transportSettings) {
        dispatch(makeSpecificCapexRequest())
      } 
    }
  }

  handleShowSettings = (settingsType, i) => {
    const settings = { ...this.state[settingsType] }
    settings[i] = !settings[i]
    this.setState({ [settingsType]: settings })
  }

  // handleBikeTypeChange = (e, data) => {
  //   const { value, name } = data
  //   const { dispatch } = this.props
  //   dispatch(
  //     updateSettings({
  //       name,
  //       value,
  //     })
  //   )
  // }

  handleHyviateChange = (e,data) => {
    const {value} = data
    const {dispatch} = this.props
    dispatch(
      updateHyviate({
        value
      })
    )
  }

  resetConfigSettings = () => {
    const { dispatch } = this.props
    dispatch(resetSettings())
  }

  extractSettings = (profile, settings) => {
    return JSON.stringify(settings)
  }

  render() {
    const { dispatch, showSettings, profile, settings,activeTab } = this.props
    const no_profile_settings = h2carrier_settings.transport[profile].boolean.length === 0
    const width = no_profile_settings ? 200 : 400

    // OVERRIDING Settings with lower level specific sttings.

    return (
      <>
        <Drawer
          enableOverlay={false}
          open={showSettings}
          direction="right"
          size="400"
          style={{
            zIndex: 1001,
            maxWidth: width,
            overflow: 'auto',
          }}
        >
          <Segment>
            <Grid columns={16} divided>
              <Grid.Row>
                {(!no_profile_settings &&activeTab===1) && (
                  <Grid.Column width={8}>
                    <Form size={'small'}>
                      <Header as="h4">H<sub>2</sub> Carrier Settings</Header>
                      <HeaderSubHeader>Conversion</HeaderSubHeader>
                      {h2carrier_settings.conversion[profile].numeric.map((option, key) => (
                        <Fragment key={key}>
                          <div className="flex pointer">
                            <div
                              onClick={() =>
                                this.handleShowSettings('conversionSettings', key)
                              }
                            >
                              <Icon
                                name={
                                  this.state.conversionSettings[key]
                                    ? 'caret down'
                                    : 'caret right'
                                }
                              />
                              <span className="b f6">{option.name}</span>
                            </div>
                            <div
                              style={{
                                marginLeft: 'auto',
                              }}
                            >
                              <Popup
                                content={option.description}
                                size={'tiny'}
                                trigger={
                                  <Icon color="grey" name="help circle" />
                                }
                              />
                            </div>
                          </div>
                          {this.state.conversionSettings[key] ? (
                            <CustomSlider
                              key={key}
                              option={option}
                              dispatch={dispatch}
                              settings={settings}
                              profile={profile}
                              handleUpdateSettings={this.handleUpdateSettings}
                            />
                          ) : null}
                        </Fragment>
                      ))}
                      <HeaderSubHeader>Transport</HeaderSubHeader>
                      {h2carrier_settings.transport[profile].numeric.map((option, key) => (
                        <Fragment key={key}>
                          <div className="flex pointer">
                            <div
                              onClick={() =>
                                this.handleShowSettings('transportSettings', key)
                              }
                            >
                              <Icon
                                name={
                                  this.state.transportSettings[key]
                                    ? 'caret down'
                                    : 'caret right'
                                }
                              />
                              <span className="b f6">{option.name}</span>
                            </div>
                            <div
                              style={{
                                marginLeft: 'auto',
                              }}
                            >
                              <Popup
                                content={option.description}
                                size={'tiny'}
                                trigger={
                                  <Icon color="grey" name="help circle" />
                                }
                              />
                            </div>
                          </div>
                          {this.state.transportSettings[key] ? (
                            <CustomSlider
                              key={key}
                              option={option}
                              dispatch={dispatch}
                              settings={settings}
                              profile={profile}
                              handleUpdateSettings={this.handleUpdateSettings}
                            />
                          ) : null}
                        </Fragment>
                      ))}
                                              {h2carrier_settings.transport[profile].enum.map((option, key) => {
                          return (
                            <div key={key} className="flex">
                              <Dropdown
                                placeholder="Select HYVIATE timeframe"
                                fluid
                                onChange={this.handleHyviateChange}
                                value={settings.hyviate_profile}
                                selection
                                name={'hyviate_profile'}
                                options={option.enums}
                              />

                              <div
                                style={{
                                  marginLeft: 'auto',
                                }}
                              >
                                <Popup
                                  content={option.description}
                                  size={'tiny'}
                                  trigger={
                                    <Icon color="grey" name="help circle" />
                                  }
                                />
                              </div>
                            </div>
                          )
                        })}
                         {h2carrier_settings.transport[profile].boolean.map(
                          (option, key) => {
                            return (
                              <div key={key} className="flex">
                                <Checkbox
                                  option={option}
                                  dispatch={dispatch}
                                  settings={settings}
                                  handleUpdateSettings={this.handleUpdateSettings}
                                />
                                <div
                                  style={{
                                    marginLeft: 'auto',
                                  }}
                                >
                                  <Popup
                                    content={option.description}
                                    size={'tiny'}
                                    trigger={
                                      <Icon color="grey" name="help circle" />
                                    }
                                  />
                                </div>
                              </div>
                            )
                          }
                        )}
                      <HeaderSubHeader>Reconversion</HeaderSubHeader>
                      {h2carrier_settings.reconversion[profile].numeric.map((option, key) => (
                        <Fragment key={key}>
                          <div className="flex pointer">
                            <div
                              onClick={() =>
                                this.handleShowSettings('reconversionSettings', key)
                              }
                            >
                              <Icon
                                name={
                                  this.state.reconversionSettings[key]
                                    ? 'caret down'
                                    : 'caret right'
                                }
                              />
                              <span className="b f6">{option.name}</span>
                            </div>
                            <div
                              style={{
                                marginLeft: 'auto',
                              }}
                            >
                              <Popup
                                content={option.description}
                                size={'tiny'}
                                trigger={
                                  <Icon color="grey" name="help circle" />
                                }
                              />
                            </div>
                          </div>
                          {this.state.reconversionSettings[key] ? (
                            <CustomSlider
                              key={key}
                              option={option}
                              dispatch={dispatch}
                              settings={settings}
                              profile={profile}
                              handleUpdateSettings={this.handleUpdateSettings}
                            />
                          ) : null}
                        </Fragment>
                      ))}
                      <Fragment>
                        {h2carrier_settings.reconversion[profile].boolean.map(
                          (option, key) => {
                            return (
                              <div key={key} className="flex">
                                <Checkbox
                                  option={option}
                                  dispatch={dispatch}
                                  settings={settings}
                                  handleUpdateSettings={this.handleUpdateSettings}
                                />
                                <div
                                  style={{
                                    marginLeft: 'auto',
                                  }}
                                >
                                  <Popup
                                    content={option.description}
                                    size={'tiny'}
                                    trigger={
                                      <Icon color="grey" name="help circle" />
                                    }
                                  />
                                </div>
                              </div>
                            )
                          }
                        )}
                      </Fragment>
                      <Divider />
                      <Fragment>

                      </Fragment>
                    </Form>
                  </Grid.Column>
                )}
                <Grid.Column width={no_profile_settings ? 16 : 8}>
                  <Form size={'small'}>
                    <div className={'flex flex-row justify-between'}>
                      <Header as="h4">General Settings</Header>
                      <Button icon onClick={() => dispatch(doShowSettings())}>
                        <Icon name="close" />
                      </Button>
                    </div>
                    <Accordion>
                      {transport_settings.all.numeric.map((option, key) => (
                        <Fragment key={key}>
                          <div className="flex pointer">
                            <div
                              onClick={() =>
                                this.handleShowSettings('generalSettings', key)
                              }
                            >
                              <Icon
                                name={
                                  this.state.generalSettings[key]
                                    ? 'caret down'
                                    : 'caret right'
                                }
                              />
                              <span className="b f6">{option.name}</span>
                            </div>
                            <div
                              style={{
                                marginLeft: 'auto',
                              }}
                            >
                              <Popup
                                content={option.description}
                                size={'tiny'}
                                trigger={
                                  <Icon color="grey" name="help circle" />
                                }
                              />
                            </div>
                          </div>
                          {this.state.generalSettings[key] ? (
                            <CustomSlider
                              key={key}
                              option={option}
                              dispatch={dispatch}
                              settings={settings}
                              profile={profile}
                              handleUpdateSettings={this.handleUpdateSettings}
                            />
                          ) : null}
                        </Fragment>
                      ))}
                      {transport_settings[profile].numeric.map((option, key) => (
                        <Fragment key={key}>
                          <div className="flex pointer">
                            <div
                              onClick={() =>
                                this.handleShowSettings('extraSettings', key)
                              }
                            >
                              <Icon
                                name={
                                  this.state.transportSettings[key]
                                    ? 'caret down'
                                    : 'caret right'
                                }
                              />
                              <span className="b f6">{option.name}</span>
                            </div>
                            <div
                              style={{
                                marginLeft: 'auto',
                              }}
                            >
                              <Popup
                                content={option.description}
                                size={'tiny'}
                                trigger={
                                  <Icon color="grey" name="help circle" />
                                }
                              />
                            </div>
                          </div>
                          {this.state.extraSettings[key] ? (
                            <CustomSlider
                              key={key}
                              option={option}
                              dispatch={dispatch}
                              settings={settings}
                              profile={profile}
                              handleUpdateSettings={this.handleUpdateSettings}
                            />
                          ) : null}
                        </Fragment>
                      ))}
                    </Accordion>
                    <Divider />
                    {transport_settings[profile].boolean.map((option, key) => {
                      return (
                        <div key={key} className="flex">
                          <Checkbox
                            key={key}
                            option={option}
                            dispatch={dispatch}
                            settings={settings}
                            handleUpdateSettings={this.handleUpdateSettings}
                          />
                          <div
                            style={{
                              marginLeft: 'auto',
                            }}
                          >
                            <Popup
                              content={option.description}
                              size={'tiny'}
                              trigger={<Icon color="grey" name="help circle" />}
                            />
                          </div>
                        </div>
                      )
                    })}
                    {transport_settings.all.boolean.map((option, key) => {
                      return (
                        <div key={key} className="flex">
                          <Checkbox
                            key={key}
                            option={option}
                            dispatch={dispatch}
                            settings={settings}
                            handleUpdateSettings={this.handleUpdateSettings}
                          />
                          <div
                            style={{
                              marginLeft: 'auto',
                            }}
                          >
                            <Popup
                              content={option.description}
                              size={'tiny'}
                              trigger={<Icon color="grey" name="help circle" />}
                            />
                          </div>
                        </div>
                      )
                    })}
                  </Form>
                </Grid.Column>
                <Grid.Column width={no_profile_settings ? 16 : 8}>
                  <Benchmark />
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>

              </Grid.Row>
              <Divider />
              <Grid.Row>
                <Grid.Column width={16}>
                  {/* <CopyToClipboard
                    text={this.extractSettings(profile, settings)}
                    onCopy={() => this.setState({ copied: true })}
                  >
                    <Button
                      basic
                      size="mini"
                      icon
                      color={this.state.copied ? 'green' : undefined}
                      labelPosition="left"
                    >
                      <Icon name="download" />
                      Copy to Clipboard
                    </Button>
                  </CopyToClipboard> */}
                  <Button
                    basic
                    size="mini"
                    icon
                    onClick={this.resetConfigSettings}
                    labelPosition="left"
                  >
                    <Icon name="remove" />
                    Reset
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment>
        </Drawer>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const { message, activeTab, showSettings } = state.common
  const { profile, settings } = state.transport
  return {
    showSettings,
    message,
    profile,
    settings,
    activeTab,
  }
}

export default connect(mapStateToProps)(SettingsPanel)
